import api from '../../../services/api';
import { PageableResponse } from '../../../types/PageableResponse.types';
import { ListAnswerKeyResponse } from '../types/template.type';

interface IProps {
	areaName?: string;
	subareaName?: string;

	pageNumber?: number;
	pageSize?: number;
}

export async function findAnswerKeyRequest({
	areaName,
	subareaName,
	pageNumber,
	pageSize,
}: IProps): Promise<PageableResponse<ListAnswerKeyResponse>> {
	const response = await api.get('/template', {
		params: {
			subareaName: subareaName || undefined,
			areaName: areaName || undefined,
			page: pageNumber ?? undefined,
			size: pageSize ?? undefined,
		},
	});

	return response.data;
}
