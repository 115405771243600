import { AxiosError } from 'axios';
import api from '../../../services/api';
import { CompetenceFormProps } from '../types/competence.type';

export async function editCompetenceRequest({ ...rest }: CompetenceFormProps) {
	try {
		const response = await api.put('/competence', rest);

		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			throw new Error(error.response?.data?.message);
		} else {
			throw new Error();
		}
	}
}
