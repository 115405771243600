import styled from 'styled-components';
import { MdScreenRotation } from 'react-icons/md';

export const PopupRotateInfo = styled.span`
	width: 100%;
	height: 18px;
	margin-top: -22px;
	margin-bottom: 4px;
	display: flex;
	flex-wrap: nowrap;
	justify-content: flex-end;
	align-items: center;

	@media (min-width: 461px) {
		display: none;
	}
`;

export const RotateDeviceIcon = styled(MdScreenRotation)`
	animation: animRotation 3s infinite;

	@keyframes animRotation {
		0% {
			transform: rotate(45deg);
		}
		35% {
			transform: rotate(45deg);
		}
		55% {
			transform: rotate(-45deg);
		}
		100% {
			transform: rotate(-45deg);
		}
	}
`;
