import { AxiosError } from 'axios';
import api from '../../../services/api';
import { HomeLinksProps } from '../types/homeLinks';

export async function findHomeLinks(): Promise<HomeLinksProps[]> {
	try {
		const response = await api.get('/homeLinks');

		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			throw new Error(error.response?.data?.message);
		} else {
			throw new Error();
		}
	}
}
