import styled from 'styled-components';
import {
	Header,
	Label as LabelSemantic,
	Message,
	Segment,
} from 'semantic-ui-react';

export const HeaderContent = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;

	> div {
		display: flex;
		align-items: center;

		> p {
			margin-left: ${({ theme }) => theme.spacing[1]}px;
		}
	}
`;

export const HeaderStyled = styled(Header)`
	color: ${({ theme }) => theme.color.primary} !important;
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;

	@media (max-width: 460px) {
		font-size: 14px !important;

		.content {
			flex: 3;
		}
	}
`;

export const Label = styled(LabelSemantic)`
	background-color: ${({ theme }) => theme.color.primary} !important;
	color: #ffffff !important;

	display: flex !important;
	align-items: center;
	justify-content: center;

	@media (max-width: 460px) {
		font-size: 16px !important;
	}
`;

export const FromTo = styled(Segment).attrs({ basic: true })`
	min-width: 200px;
	margin: 0 !important;
	display: grid;
	grid-template-columns: minmax(100px, auto) 40px minmax(100px, auto);
	align-items: center;
	justify-content: center;
	gap: 14px;

	flex: 1;

	> span {
		text-align: center;
	}

	#arrowDown {
		display: none;
	}
	#arrowRight {
		display: inline-block;
	}

	@media (min-width: 870px) {
		grid-template-columns: none;

		#arrowDown {
			display: inline-block;
		}
		#arrowRight {
			display: none;
		}
	}
`;

export const MessageStyled = styled(Message).attrs({ warning: true })`
	margin: 0 !important;

	flex: 3;
`;

export const FromToAndMessage = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 14px;

	@media (max-width: 869px) {
		flex-direction: column;
	}
`;
