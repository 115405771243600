import { Button, Form, Icon } from 'semantic-ui-react';

import {
	Container,
	FilterBody,
	FilterHeader,
	FilterSubmit,
	Logo,
} from './styles';
import { ButtonGDY } from '../Form/ButtonGDY';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
	children?: React.ReactNode;
	show: boolean;
	handleClose(): void;
	onApplyClick(): void;
	onClearClick(): void;
}

export function FilterBar({
	children,
	show,
	handleClose,
	onApplyClick,
	onClearClick,
}: IProps) {
	const { t } = useTranslation();
	const ref = useRef(null);

	return (
		<Container ref={ref} show={show}>
			<Form>
				<FilterHeader>
					<Logo className="filter__logo" />
					<div className="filter__title">{t('component.filter.header')}</div>
					<div className="filter__close">
						<Icon name="close" onClick={handleClose} fontSize="2em" />
					</div>
				</FilterHeader>

				<FilterBody>{children}</FilterBody>

				<FilterSubmit>
					<Button.Group widths="2">
						<ButtonGDY
							content={t('component.filter.button.clear')}
							secondary
							onClick={onClearClick}
							basic
							fluid
						/>
						<ButtonGDY
							content={t('component.filter.button.apply')}
							primary
							onClick={() => {
								onApplyClick();
								handleClose();
							}}
							fluid
						/>
					</Button.Group>
				</FilterSubmit>
			</Form>
		</Container>
	);
}
