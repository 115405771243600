import styled from 'styled-components';
import { Colors, FontSize, FontWeight } from '../../app/styles/theme';
import { CSSProperties } from 'react';

type ParagraphProps = {
	color?: Colors;
	variant?: FontSize;
	weight?: FontWeight;
	align?: CSSProperties['textAlign'];
}

export const Paragraph = styled.p<ParagraphProps>`
    margin: 0;
    color: ${({ theme, color }) => theme.color[color as Colors]};
    font-weight: ${({ theme, weight }) => theme.typography.weight[weight as FontWeight]};
    font-size: ${({ theme, variant }) => theme.typography.size[variant as FontSize]}px;
    text-align: ${({ align }) => align};
`;

Paragraph.defaultProps = {
	color: 'text',
	variant: 'description',
	weight: 'regular',
	align: 'left',
};
