import { useQuery } from '@tanstack/react-query';
import { findBehaviorRequest } from '../requests/findBehaviorRequest';
import { FilterListBehaviors } from '../types/behavior.type';

type UseFindBehaviors = {
	page: number;
	filter?: FilterListBehaviors;
};

export function useFindBehaviors({ page, filter }: UseFindBehaviors) {
	const { data: listBehaviors } = useQuery(
		['BEHAVIOR_LIST', page, filter],
		async () =>
			findBehaviorRequest({
				...filter,
				pageNumber: page - 1,
				pageSize: 10,
			}),
		{ retry: false }
	);

	return { listBehaviors };
}
