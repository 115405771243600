import { Title } from '../../../../components/Typography/Title';
import { Toolbar } from '../../../../components/Toolbar';
import { Table } from '../../../../components/Table';
import { Container } from './styles';
import {
	Button,
	Dropdown,
	Form,
	Icon,
	Label,
	PaginationProps,
	Popup,
} from 'semantic-ui-react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { FilterGroup } from '../../../../components/Form/FilterGroup';
import { Controller, useForm } from 'react-hook-form';
import { useDeleteFunction } from '../../hooks/useDeleteFunction';
import { useFindFunctions } from '../../hooks/useFindFunctions';
import { FilterListFunctions } from '../../types/function.type';
import { NoResultsTableInformation } from '../../../../components/TableNoDataFound';
import { useTranslation } from 'react-i18next';
import { TableHealderProps } from '../../../../types/table.types';
import { ModalConfirm } from '../../../../components/ModalConfirm';

const tableHeader: TableHealderProps[] = [
	{ name: 'page.functions.function' },
	{
		name: 'page.functions.description',
	},
	{ name: 'page.functions.order', textAlign: 'center' },
	{ name: 'page.functions.active', width: 2, textAlign: 'center' },
];

export function ListFunctions() {
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [item, setItem] = useState<number>();

	const { t } = useTranslation();
	const { control, handleSubmit, reset } = useForm<FilterListFunctions>({
		defaultValues: {
			active: true,
		},
	});
	const [page, setPage] = useState(1);
	const [filter, setFilter] = useState<FilterListFunctions>({ active: true });

	const navigate = useNavigate();
	const location = useLocation();

	const { listFunctions } = useFindFunctions({ page, filter });
	const { deleteFunction } = useDeleteFunction();

	const onSubmitFilter = (data: FilterListFunctions) => {
		setFilter(data);
		setPage(1);
	};

	const handleResetFilter = () => {
		reset({
			name: '',
			description: '',
			order: '',
			active: true,
		});
		setFilter({
			name: undefined,
			description: undefined,
			order: undefined,
			active: true,
		});
	};

	const handlePageChange = (
		_: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
		data: PaginationProps
	) => {
		setPage(Number(data.activePage));
	};

	return (
		<Container>
			<Title>{t('title.functions')}</Title>

			<Toolbar
				handleAdd={() => navigate(`${location.pathname}/create`)}
				filter={{
					content: (
						<>
							<FilterGroup>
								<Controller
									control={control}
									name="name"
									render={({ field: { value, onChange } }) => (
										<Form.Input
											label={t('page.functions.function')}
											value={value}
											onChange={onChange}
										/>
									)}
								/>

								<Controller
									control={control}
									name="description"
									render={({ field: { value, onChange } }) => (
										<Form.TextArea
											label={t('page.functions.description')}
											value={value}
											onChange={onChange}
										/>
									)}
								/>

								<Controller
									control={control}
									name="order"
									render={({ field: { value, onChange } }) => (
										<Form.Input
											type="number"
											label={t('page.functions.order')}
											value={value}
											onChange={onChange}
										/>
									)}
								/>
							</FilterGroup>

							<FilterGroup>
								<Controller
									control={control}
									name="active"
									render={({ field: { value, onChange } }) => (
										<Form.Radio
											toggle
											label={t('page.functions.active')}
											checked={value}
											onChange={(_, data) => onChange(data.checked)}
										/>
									)}
								/>
							</FilterGroup>
						</>
					),
					onApply: handleSubmit(onSubmitFilter),
					onClear: handleResetFilter,
				}}
			></Toolbar>

			<span>
				<Table>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell content="ID" isIdColumn />
							{tableHeader.map(({ name, textAlign, width }) => (
								<Table.HeaderCell
									key={name}
									width={width}
									textAlign={textAlign}
								>
									{t(name as any)}
								</Table.HeaderCell>
							))}

							<Table.HeaderCell isActionColumn>
								<Icon name="cog" size="large" />
							</Table.HeaderCell>
						</Table.Row>
					</Table.Header>

					<Table.Body>
						{listFunctions?._embedded?.functions?.map(
							({ id, active, name, description, order }, index) => (
								<Table.Row key={id}>
									<Table.Cell content={index + 1} />
									<Table.Cell content={name} />
									<Table.Cell content={description} />
									<Table.Cell textAlign="center" content={order} />
									<Table.Cell width={2} textAlign="center">
										<Label
											color={active ? 'green' : 'red'}
											content={active ? 'Active' : 'Deactivate'}
										/>
									</Table.Cell>
									<Table.Cell isActionColumn>
										<Dropdown
											pointing="right"
											trigger={<Icon name="ellipsis vertical" size="large" />}
											icon={null}
										>
											<Dropdown.Menu>
												<span className="actionColumn__buttonGroup">
													<Popup
														content={t('tooltip.edit')}
														trigger={
															<Button
																icon="pencil"
																size="mini"
																color="yellow"
																onClick={() =>
																	navigate(`${location.pathname}/update/${id}`)
																}
															/>
														}
													/>

													<Popup
														content={t('tooltip.delete')}
														trigger={
															<Button
																icon="trash"
																size="mini"
																color="red"
																onClick={() => {
																	setItem(id);
																	setOpenModal(true);
																}}
															/>
														}
													/>
												</span>
											</Dropdown.Menu>
										</Dropdown>
									</Table.Cell>
								</Table.Row>
							)
						)}
					</Table.Body>
				</Table>

				{listFunctions?._embedded?.functions?.length ? (
					<Table.Pagination
						page={page}
						activePage={page}
						totalPages={listFunctions?.page.totalPages ?? 0}
						boundaryRange={1}
						siblingRange={0}
						onPageChange={handlePageChange}
						size="mini"
						firstItem={null}
						lastItem={null}
						secondary
					/>
				) : (
					<NoResultsTableInformation
						icon="dont"
						title={t('message.noDataFound.title')}
						subTitle={t('message.noDataFound.subTitle')}
					/>
				)}
			</span>

			<Outlet />

			<ModalConfirm
				open={openModal}
				onClose={() => setOpenModal(false)}
				onOpen={() => setOpenModal(true)}
				title={t('component.modal.header.confirmation')}
				description={t('component.modal.content.AreYouSureYouWantToDelete')}
				type="remove"
				size="mini"
				onConfirm={() => {
					item && deleteFunction.mutateAsync(item);
					setOpenModal(false);
				}}
				onCancel={() => setOpenModal(false)}
			/>
		</Container>
	);
}
