import api from '../../../services/api';
import { SubareaResponseProps } from '../types/subarea.type';

export async function findSubareaByIdRequest(
	id: number
): Promise<SubareaResponseProps> {
	const response = await api.get(`/sub-area/${id}`);

	return response.data;
}
