import { AxiosError } from 'axios';
import api from '../../../services/api';
import { HomeLinksProps } from '../types/homeLinks';

export async function editHomeLinksRequest(data: HomeLinksProps) {
	try {
		const response = await api.put('/homeLinks', {
			id: data.id,
			content: data.content,
		});

		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			throw new Error(error.response?.data?.message);
		} else {
			throw new Error();
		}
	}
}
