import { useNavigate, useParams } from 'react-router-dom';
import { Form, Modal } from 'semantic-ui-react';
import { ButtonGDY } from '../../../../components/Form/ButtonGDY';
import { useQuery } from '@tanstack/react-query';
import { findCapacityLevelByIdRequest } from '../../requests/findCapacityLevelByIdRequest';
import { useForm } from 'react-hook-form';
import { useCreateCapacityLevel } from '../../hooks/useCreateCapacityLevel';
import { useEditCapacityLevel } from '../../hooks/useEditCapacityLevel';
import { CapacityLevelFormProps } from '../../types/capacityLevel.type';
import { FormInput } from '../../../../components/Form/FormInput';
import { FormCheckbox } from '../../../../components/Form/FormCheckbox';
import { useTranslation } from 'react-i18next';

export function CapacityLevelFormModal() {
	const { t } = useTranslation();
	const { control, reset, handleSubmit } = useForm<CapacityLevelFormProps>({
		defaultValues: {
			active: true,
		},
	});
	const navigate = useNavigate();

	const { capacityLevelId } = useParams<'capacityLevelId'>();

	const { createCapacityLevel } = useCreateCapacityLevel();
	const { editCapacityLevel } = useEditCapacityLevel();

	useQuery(
		['CAPACITY_LEVEL_BY_ID'],
		async () => {
			const data = await findCapacityLevelByIdRequest(Number(capacityLevelId));

			reset({
				id: data.id,
				name: data.name,
				active: data.active,
			});
		},
		{
			enabled: !!capacityLevelId,
			retry: false,
			refetchOnWindowFocus: false,
		}
	);

	const onSubmit = (data: CapacityLevelFormProps) => {
		if (data.id) {
			return editCapacityLevel.mutateAsync(data);
		}

		return createCapacityLevel.mutateAsync(data);
	};

	return (
		<Modal
			open
			size="tiny"
			onClose={() => navigate(-1)}
			closeIcon
			closeOnDimmerClick={false}
		>
			<Modal.Header>
				{t('page.capacityLevels.capacityLevelRegistration')}
			</Modal.Header>

			<Modal.Content scrolling>
				<Form>
					<FormInput<CapacityLevelFormProps>
						control={control}
						name="name"
						label={t('page.capacityLevels.capacityLevel')}
						required
					/>

					<FormCheckbox<CapacityLevelFormProps>
						control={control}
						name="active"
						label={t('page.capacityLevels.active')}
					/>
				</Form>
			</Modal.Content>

			<Modal.Actions>
				<ButtonGDY
					onClick={handleSubmit(onSubmit)}
					loading={createCapacityLevel.isLoading || editCapacityLevel.isLoading}
					disabled={
						createCapacityLevel.isLoading || editCapacityLevel.isLoading
					}
				>
					{t('component.modal.button.save')}
				</ButtonGDY>
			</Modal.Actions>
		</Modal>
	);
}
