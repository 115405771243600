/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Modal } from 'semantic-ui-react';
import { ButtonGDY } from '../../../../components/Form/ButtonGDY';
import { useQuery } from '@tanstack/react-query';

import { findBehaviorByIdRequest } from '../../requests/findBehaviorByIdRequest';
import { useForm, useWatch } from 'react-hook-form';
import { useCreateBehavior } from '../../hooks/useCreateBehavior';
import { useEditBehavior } from '../../hooks/useEditBehavior';
import { BehaviorFormProps } from '../../types/behavior.type';
import { toDropdownItemProps } from '../../../../utils/toDropdownItemProps';
import { CompetenceResponseProps } from '../../../competencies/types/competence.type';
import { findCapacityLevelRequest } from '../../../capacityLevels/requests/findCapacityLevelRequest';
import { CapacityLevelResponseProps } from '../../../capacityLevels/types/capacityLevel.type';
import { findAreaRequest } from '../../../areas/requests/findAreaRequest';
import { AreaResponseProps } from '../../../areas/types/area.type';
import { FormDropdown } from '../../../../components/Form/FormDropdown';
import { FormTextArea } from '../../../../components/Form/FormTextArea';
import { FormCheckbox } from '../../../../components/Form/FormCheckbox';
import { useTranslation } from 'react-i18next';
import { findCompetenceByAreaIdRequest } from '../../../competencies/requests/findCompetenceByAreaIdRequest';
import { useEffect } from 'react';

export function BehaviorFormModal() {
	// @ts-ignore
	const { t } = useTranslation();
	const { control, reset, handleSubmit, setValue } = useForm<BehaviorFormProps>(
		{
			defaultValues: {
				active: true,
			},
		}
	);
	const navigate = useNavigate();

	const { behaviorId } = useParams<'behaviorId'>();

	const { createBehavior } = useCreateBehavior();
	const { editBehavior } = useEditBehavior();

	const watchAreaId = useWatch({ control, name: 'areaId' });

	const cleanFields = () => {
		if (!watchAreaId) {
			setValue('competenceId', '');
		}
	};

	useEffect(() => {
		cleanFields();
	}, [watchAreaId]);

	const { data: areaOptions } = useQuery(['AREA_OPTIONS'], async () => {
		const data = await findAreaRequest({});

		return toDropdownItemProps<AreaResponseProps>(data._embedded.areas);
	});

	const { data: competenceOptions } = useQuery(
		['COMPETENCE_OPTIONS', watchAreaId],
		async () => {
			const data = await findCompetenceByAreaIdRequest(Number(watchAreaId));

			return toDropdownItemProps<CompetenceResponseProps>(data);
		},
		{
			enabled: !!watchAreaId,
			retry: false,
		}
	);

	const { data: capacityLevelOptions } = useQuery(
		['CAPACITY_LEVEL_OPTIONS'],
		async () => {
			const data = await findCapacityLevelRequest({});

			return toDropdownItemProps<CapacityLevelResponseProps>(
				data._embedded.capacityLevels
			);
		}
	);

	useQuery(
		['BEHAVIOR_BY_ID'],
		async () => {
			const data = await findBehaviorByIdRequest(Number(behaviorId));

			reset({
				id: data.id,
				areaId: data.competence.area.id,
				competenceId: data.competence.id,
				capacityLevelId: data.capacityLevel.id,
				description: data.description,
				active: data.active,
			});
		},
		{
			enabled: !!behaviorId,
			retry: false,
			refetchOnWindowFocus: false,
		}
	);

	const onSubmit = (data: BehaviorFormProps) => {
		if (data.id) {
			return editBehavior.mutateAsync(data);
		}

		return createBehavior.mutateAsync(data);
	};

	return (
		<Modal
			open
			size="tiny"
			onClose={() => navigate(-1)}
			closeIcon
			closeOnDimmerClick={false}
		>
			<Modal.Header>{t('page.behaviors.behaviorRegistration')}</Modal.Header>

			<Modal.Content scrolling>
				<Form>
					<FormDropdown<BehaviorFormProps>
						control={control}
						name="areaId"
						label={t('page.behaviors.area')}
						options={areaOptions}
						required
					/>

					<FormDropdown<BehaviorFormProps>
						control={control}
						name="competenceId"
						label={t('page.behaviors.competence')}
						options={competenceOptions}
						required
						disabled={!watchAreaId}
					/>

					<FormDropdown<BehaviorFormProps>
						control={control}
						name="capacityLevelId"
						label={t('page.behaviors.capacityLevel')}
						options={capacityLevelOptions}
						required
						disabled={!watchAreaId}
					/>

					<FormTextArea<BehaviorFormProps>
						control={control}
						name="description"
						label={t('page.behaviors.behavior')}
						required
					/>

					<FormCheckbox<BehaviorFormProps>
						control={control}
						name="active"
						label={t('page.behaviors.active')}
					/>
				</Form>
			</Modal.Content>

			<Modal.Actions>
				<ButtonGDY
					onClick={handleSubmit(onSubmit)}
					loading={createBehavior.isLoading || editBehavior.isLoading}
					disabled={createBehavior.isLoading || editBehavior.isLoading}
				>
					{t('component.modal.button.save')}
				</ButtonGDY>
			</Modal.Actions>
		</Modal>
	);
}
