import { Grid, Segment } from 'semantic-ui-react';
import styled from 'styled-components';

export const Container = styled.div`
	width: 80vw;
	margin: 0 auto;

	@media (min-width: 1600px) {
		width: 60vw;
	}

	@media (max-width: 460px) {
		width: 100%;
	}
`;

export const GridRow = styled(Grid.Row)`
	margin: 1rem 0;
`;

export const TextBox = styled(Segment)`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;

export const TextContent = styled.div``;
