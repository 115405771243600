import api from '../../../services/api';
import { BehaviorResponseProps } from '../types/behavior.type';

export async function findBehaviorByIdRequest(
	id: number
): Promise<BehaviorResponseProps> {
	const response = await api.get(`/behavior/${id}`);

	return response.data;
}
