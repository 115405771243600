import { Button, Icon, Popup } from 'semantic-ui-react';
import { ButtonGDY } from '../Form/ButtonGDY';
import { Container, RightElements } from './styles';
import React, { useState } from 'react';
import { FilterBar } from '../FilterBar';
import { useTranslation } from 'react-i18next';

interface IProps {
	handleAdd(): void;

	filter?: {
		content?: React.ReactNode;
		onApply(): void;
		onClear(): void;
	};

	elements?: React.ReactNode;
}

export function Toolbar({ handleAdd, filter, elements }: IProps) {
	const { t } = useTranslation();
	const [showFilterBar, setShowFilterBar] = useState(false);

	function toggleFilterBar() {
		setShowFilterBar((prev) => !prev);
	}

	return (
		<>
			<Container>
				<div className="content">
					<div>
						<ButtonGDY
							className="add-button"
							icon
							labelPosition="left"
							onClick={handleAdd}
						>
							<Icon name="add" />
							{t('component.toolbar.new')}
						</ButtonGDY>
					</div>

					<RightElements>
						{elements}

						<Button.Group>
							{!!filter && (
								<Popup
									// disabled={isMobileScreen}
									content={t('tooltip.filter')}
									trigger={
										<ButtonGDY
											className="filter-list"
											icon="filter"
											active={showFilterBar}
											onClick={toggleFilterBar}
										/>
									}
								/>
							)}
						</Button.Group>
					</RightElements>
				</div>
			</Container>

			<FilterBar
				show={showFilterBar}
				handleClose={() => setShowFilterBar(false)}
				onApplyClick={() => filter?.onApply()}
				onClearClick={() => filter?.onClear()}
			>
				{filter?.content}
			</FilterBar>
		</>
	);
}
