import api from '../../../services/api';
import { SubareaResponseProps } from '../types/subarea.type';

export async function findSubareaByAreaIdRequest(
	id: number
): Promise<Array<SubareaResponseProps>> {
	const response = await api.get(`/sub-area/area/${id}`);

	return response.data;
}
