import { useMutation } from '@tanstack/react-query';
import { createAnswerKeyRequest } from '../requests/createAnswerKeyRequest';
import { useNavigate } from 'react-router-dom';
import { AnswerKeyFormRequestProps } from '../types/template.type';
import { Alert } from '../../../components/Alert';
import { queryClient } from '../../../app/App';
import { AxiosError } from 'axios';

export function useCreateAnswerKey() {
	const navigate = useNavigate();

	const createAnswerKey = useMutation({
		mutationFn: async (data: AnswerKeyFormRequestProps[]) => {
			return createAnswerKeyRequest(data);
		},
		onSuccess: () => {
			Alert({ message: 'Successfully created!', type: 'success' });
			queryClient.invalidateQueries(['TEMPLATE_LIST']);
			navigate(-1);
		},
		onError: (error: AxiosError) => {
			Alert({ message: error.message, type: 'error' });
			queryClient.invalidateQueries(['TEMPLATE_LIST']);
		},
	});

	return { createAnswerKey };
}
