import {
	Chart as ChartJS,
	LinearScale,
	CategoryScale,
	BarElement,
	PointElement,
	LineElement,
	Legend,
	Tooltip,
	LineController,
	BarController,
	TooltipItem,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { Bar } from 'react-chartjs-2';
import { Loader } from 'semantic-ui-react';

import { Container } from './styles';
import { useTheme } from 'styled-components';

ChartJS.register(
	LinearScale,
	CategoryScale,
	BarElement,
	PointElement,
	LineElement,
	Legend,
	Tooltip,
	LineController,
	BarController,
	ChartDataLabels
);

export interface ChartDataProps {
	labels?: string[];
	datasets: {
		label?: string;
		data?: number[];
		dataInPercent?: (number | string | undefined)[];
		backgroundColor?: string[];
	}[];
	simpleAverage?: number;
}

interface Props {
	title?: string;
	data?: ChartDataProps;
	isLoading?: boolean;
}

export function Chart({ title, data, isLoading }: Props) {
	const { color } = useTheme();
	const backgroundColors = [
		color.secondary,
		color.primary,
		color.secondary,
		'#ee6e51',
		'#f50404',
		'#0fb5c5',
		'#4ff246',
	];

	return (
		<Container>
			{title && <div className="minichartbox__title">{title}</div>}

			<div className="minichartbox__content">
				{isLoading ? (
					<Loader inline active />
				) : (
					<Bar
						data={{
							labels: data?.labels ?? [],
							datasets:
								data?.datasets
									?.map((item, index) => ({
										type: (index === 0 ? 'line' : 'bar') as any,
										label: item.label,
										data: item.data,
										backgroundColor: backgroundColors?.[index],
									}))
									.filter((_, index) => index !== 0) ?? [],
						}}
						options={{
							layout: {
								padding: 30,
							},
							scales: {
								x: {
									ticks: {
										font: {
											size: 16,
											weight: 'bold',
										},
									},
								},
								x2: {
									labels: data?.datasets[0].data?.map(
										(media) => `Média: ${media}%`
									),
									ticks: {
										font: {
											size: 14,
										},
									},
								},
							},
							responsive: true,
							plugins: {
								tooltip: {
									callbacks: {
										label: function (tooltipItem: TooltipItem<'bar'>) {
											if (!tooltipItem?.formattedValue) {
												return undefined;
											}

											return `${tooltipItem.formattedValue}%`;
										},
									},
								},
								legend: {
									display: true,
									position: 'bottom' as const,
								},
								datalabels: {
									formatter: (value) => {
										if (value) {
											return `${value}%`;
										}
										return null;
									},
									align: 'end',
									anchor: 'end',
									color: 'black',
									labels: {
										title: {
											font: {
												weight: 'normal',
											},
										},
										value: {
											color: '#333333',
										},
									},
								},
							},
						}}
					/>
				)}
			</div>
		</Container>
	);
}
