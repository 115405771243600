import styled from 'styled-components';
import { Button } from 'semantic-ui-react';

interface ButtonProps {
	outline: boolean;
}

export const Container = styled(Button)<ButtonProps>`
	border: ${({ theme, outline }) =>
		outline && `2px solid ${theme.color.primary}`} !important;
	background-color: ${({ theme, outline }) =>
		outline ? theme.color.white : theme.color.primary} !important;
	color: ${({ theme, outline }) =>
		outline ? theme.color.primary : theme.color.white} !important;

	i {
		opacity: 1 !important;
		color: ${({ theme, outline }) =>
			outline ? theme.color.primary : theme.color.white} !important;
	}

	display: flex !important;
	justify-content: center;
	align-items: center;
	flex-wrap: nowrap;
	gap: 8px;

	&:hover {
		background-color: ${({ theme, outline }) =>
			!outline && theme.color.primary_dark} !important;
	}
`;
