import { AxiosError } from 'axios';
import api from '../../../services/api';
import { QuizBehaviorsResponseProps } from '../types/quiz.type';

interface IProps {
	toSubAreaId: number;
	toFunctionId: number;
}

export async function findMyQuizRequest({
	toSubAreaId,
	toFunctionId,
}: IProps): Promise<QuizBehaviorsResponseProps[]> {
	try {
		const response = await api.get('/quiz/behaviors', {
			params: {
				toSubAreaId,
				toFunctionId,
			},
		});

		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			throw new Error(error.response?.data?.message);
		} else {
			throw new Error();
		}
	}
}
