import { Controller, FieldValues, Path, useFormState } from 'react-hook-form';
import {
	DropdownIndicatorProps,
	GroupBase,
	StylesConfig,
	components,
} from 'react-select';
import {
	LoadOptions,
	WithAsyncPaginateType,
} from 'react-select-async-paginate';
import { Form, Label } from 'semantic-ui-react';
import { AsyncPaginate } from 'react-select-async-paginate';
import { useTranslation } from 'react-i18next';
import { OptionType } from '../../../features/answerKeys/types/template.type';

type Props<T extends FieldValues> = Partial<WithAsyncPaginateType> & {
	control: any;
	name: Path<T>;
	required?: boolean;
	messageError?: string;
	placeholder?: string;
	label: string;
	disabled: boolean;
	loadOptions?: LoadOptions<
		OptionType,
		GroupBase<OptionType>,
		{
			page: number;
		}
	>;
};

export function FormAsyncDropdown<T extends FieldValues>({
	control,
	name,
	placeholder,
	label,
	loadOptions,
	disabled,
	required,
	messageError,
	...rest
}: Props<T>) {
	const { t } = useTranslation();
	const { errors } = useFormState({
		control,
	});

	return (
		<Controller
			control={control}
			name={name}
			rules={{ required: required }}
			render={({ field: { value, onChange } }) => (
				<Form.Field
					required={required}
					disabled={disabled}
					error={errors[name]}
				>
					<label>{label}</label>
					<AsyncPaginate
						isMulti
						placeholder={placeholder ?? t('component.dropdown.select')}
						value={value}
						loadOptions={loadOptions as any}
						onChange={onChange}
						additional={{ page: 1 }}
						closeMenuOnSelect={false}
						isSearchable={false}
						styles={customStyles(!!errors[name])}
						components={{ DropdownIndicator }}
						{...rest}
					/>
					{errors[name] && (
						<Label
							basic
							pointing
							style={{
								color: '#9f3a38',
								border: '1px solid #e0b4b4',
							}}
						>
							{messageError ?? t('message.error.thisFieldIsRequired')}
						</Label>
					)}
				</Form.Field>
			)}
		/>
	);
}

const DropdownIndicator = (props: DropdownIndicatorProps<any>) => {
	return (
		<components.DropdownIndicator {...props}>
			<i className="dropdown icon"></i>
		</components.DropdownIndicator>
	);
};

const customStyles = (hasError: boolean): StylesConfig<OptionType, true> => ({
	control: (provided, state) => ({
		...provided,
		backgroundColor: hasError ? '#fff6f6' : '#ffffff',
		borderColor: hasError ? '#e0b4b4' : state.isFocused ? '#85b7d9' : '#d4d4d5',
		boxShadow: state.isFocused ? '0 2px 3px 2px rgba(34,36,38,.15)' : 'none',
		'&:hover': {
			borderColor: '#85b7d9',
		},
	}),
	menu: (provided) => ({
		...provided,
		backgroundColor: hasError ? '#fff6f6' : '#ffffff',
		color: hasError ? '#e0b4b4' : '#ffffff',
		zIndex: 9999,
	}),
	valueContainer: (provided) => ({
		...provided,
		padding: '0.22619048em 2.1em 0.22619048em 0.35714286em',
	}),
	placeholder: (provided) => ({
		...provided,
		color: hasError ? '#9f3a38' : '#BFBFBFDE',
	}),
	option: (provided, state) => ({
		...provided,
		backgroundColor: state.isSelected
			? '#85b7d9'
			: state.isFocused
			? 'rgba(0,0,0,.03)'
			: 'none',
		color: hasError ? '#9f3a38' : state.isSelected ? 'white' : 'black',
		'&:active': {
			backgroundColor: state.isSelected ? '#85b7d9' : '#d4d4d5',
		},
		'&:hover': {
			backgroundColor: hasError ? '#fbe7e7' : '#f4f4f4',
		},
	}),
	multiValue: (provided) => ({
		...provided,
		backgroundColor: '#e0e1e2',
		borderRadius: '.28571429rem',
		display: 'flex',
		margin: '2px',
		minWidth: '0',
		boxShadow: '0 0 0 1px rgba(34,36,38,.15) inset',
	}),
	multiValueLabel: (provided) => ({
		...provided,
		color: '#00000099',
		fontWeight: 'bold',
		fontSize: '1em',
	}),
	multiValueRemove: (provided) => ({
		...provided,
		color: 'rgba(0,0,0,.6)',
		cursor: 'pointer',
		':hover': {
			backgroundColor: '#d4d4d5',
			color: 'black',
		},
	}),
	indicatorSeparator: () => ({
		display: 'none',
	}),
});
