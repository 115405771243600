import { useMutation } from '@tanstack/react-query';
import { Alert } from '../../../components/Alert';
import { queryClient } from '../../../app/App';
import { AxiosError } from 'axios';
import { createHomeLinksRequest } from '../requests/createHomeLinksRequest';

type Props = {
	setIsHomeLinksEdit: React.Dispatch<React.SetStateAction<boolean>>;
};

export function useCreateHomeLinks({ setIsHomeLinksEdit }: Props) {
	const createHomeLinks = useMutation({
		mutationFn: async (data: string) => {
			return createHomeLinksRequest(data);
		},
		onSuccess: () => {
			Alert({ message: 'Successfully created!', type: 'success' });
			queryClient.invalidateQueries(['HOME_LINKS']);

			setIsHomeLinksEdit(false);
		},
		onError: (error: AxiosError) => {
			Alert({ message: error.message, type: 'error' });
		},
	});

	return { createHomeLinks };
}
