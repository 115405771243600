/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
	Control,
	Controller,
	FieldValues,
	Path,
	useFormState,
} from 'react-hook-form';
import { Form, FormTextAreaProps } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

type Props<T extends FieldValues> = FormTextAreaProps & {
	control: Control<T>;
	name: Path<T>;
	required?: boolean;
	messageError?: string;

	label: string;
	placeholder?: string;
};

export function FormTextArea<T extends FieldValues>({
	control,
	name,
	required,
	messageError,

	label,
	placeholder,
	...rest
}: Props<T>) {
	// @ts-ignore
	const { t } = useTranslation();
	const { errors } = useFormState<T>({
		control,
	});

	return (
		<Controller
			control={control}
			name={name}
			rules={{ required: required }}
			render={({ field: { value, onChange } }) => (
				<Form.TextArea
					label={label}
					placeholder={placeholder ?? label}
					value={value}
					onChange={onChange}
					required={required}
					error={
						required &&
						errors[name] &&
						(messageError ?? t('message.error.thisFieldIsRequired'))
					}
					{...rest}
				/>
			)}
		/>
	);
}
