import api from '../../../services/api';
import { AnswerKeyResponseProps } from '../types/template.type';

export async function findAnswerKeyByIdRequest(
	id: number
): Promise<AnswerKeyResponseProps[]> {
	const response = await api.get(`/template/${id}`);

	return response.data;
}
