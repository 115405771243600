import { useQuery } from '@tanstack/react-query';
import { findFunctionRequest } from '../../functions/requests/findFunctionRequest';
import { useMemo } from 'react';
import { toDropdownItemProps } from '../../../utils/toDropdownItemProps';
import { findAreaRequest } from '../../areas/requests/findAreaRequest';
import { findSubareaByAreaIdRequest } from '../../subareas/requests/findSubareaByAreaIdRequest';

type Props = {
	fromAreaId: number;
	toAreaId: number;
};

export function useQuizFormData({ fromAreaId, toAreaId }: Props) {
	const { data: areas } = useQuery(
		['AREA_LIST'],
		async () => (await findAreaRequest({}))._embedded.areas
	);

	const { data: subareasByFromAreaId } = useQuery(
		['SUBAREA_BY_FROMAREAID_OPTIONS', fromAreaId],
		async () => findSubareaByAreaIdRequest(fromAreaId),
		{
			enabled: !!fromAreaId,
			retry: false,
			refetchOnWindowFocus: false,
		}
	);

	const { data: subareasByToAreaId } = useQuery(
		['SUBAREA_BY_TOAREAID_OPTIONS', toAreaId],
		async () => findSubareaByAreaIdRequest(toAreaId),
		{
			enabled: !!toAreaId,
			retry: false,
			refetchOnWindowFocus: false,
		}
	);

	const { data: functions } = useQuery(
		['FUNCTION_LIST'],
		async () => (await findFunctionRequest({}))._embedded.functions,
		{
			retry: false,
		}
	);

	const areaOptions = useMemo(
		() => areas && toDropdownItemProps(areas),
		[areas]
	);

	const fromSubareaOptions = useMemo(
		() => subareasByFromAreaId && toDropdownItemProps(subareasByFromAreaId),
		[subareasByFromAreaId]
	);

	const toSubareaOptions = useMemo(
		() => subareasByToAreaId && toDropdownItemProps(subareasByToAreaId),
		[subareasByToAreaId]
	);

	const functionOptions = useMemo(
		() => functions && toDropdownItemProps(functions),
		[functions]
	);

	return {
		areas,
		functions,

		areaOptions,
		fromSubareaOptions,
		toSubareaOptions,
		functionOptions,
	};
}
