import { AxiosError } from 'axios';
import api from '../../../services/api';

type Props = {
	id?: number;
	name: string;
};

export async function editAreaRequest({ id, name }: Props) {
	try {
		const response = await api.put('/area', {
			id,
			name,
		});

		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			throw new Error(error.response?.data?.message);
		} else {
			throw new Error();
		}
	}
}
