import { Button, Header, Icon, Modal, ModalProps, SemanticCOLORS, SemanticICONS } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

type ModalConfirmProps = ModalProps & {
	title: string;
	description: string;
	type?: 'default' | 'remove'
	onConfirm: () => void;
	onCancel: () => void;
}

export function ModalConfirm({
	title,
	description,
	type = 'default',
	onConfirm,
	onCancel,
	...next
}: ModalConfirmProps) {
	const { t } = useTranslation();

	const options = {
		default: {
			cancel: {
				label: t('component.modal.button.cancel'),
				color: 'red',
				icon: 'remove'
			},
			confirm: {
				label: t('component.modal.button.confirm'),
				color: 'green',
				icon: 'checkmark'
			}
		},
		remove: {
			cancel: {
				label: t('component.modal.button.cancel'),
				color: 'gray',
				icon: 'remove'
			},
			confirm: {
				label: t('component.modal.button.delete'),
				color: 'red',
				icon: 'trash'
			}
		}
	};

	const option = options[type];

	return (
		<Modal {...next}>
			<Header content={title} />
			<Modal.Content>
				<p>{description}</p>
			</Modal.Content>
			<Modal.Actions>
				<Button
					labelPosition='left'
					icon
					color={option.cancel.color as SemanticCOLORS}
					onClick={onCancel}
				>
					<Icon name={option.cancel.icon as SemanticICONS} />
					{option.cancel.label}
				</Button>
				<Button
					labelPosition='left'
					icon
					color={option.confirm.color as SemanticCOLORS}
					onClick={onConfirm}
				>
					<Icon name={option.confirm.icon as SemanticICONS} />
					{option.confirm.label}
				</Button>
			</Modal.Actions>
		</Modal>
	);
}
