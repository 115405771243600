import { useQuery } from '@tanstack/react-query';
import { findFunctionRequest } from '../requests/findFunctionRequest';
import { FilterListFunctions } from '../types/function.type';

type UseFindFunctions = {
	page: number;
	filter?: FilterListFunctions;
};

export function useFindFunctions({ page, filter }: UseFindFunctions) {
	const { data: listFunctions } = useQuery(
		['RESPONSIBILITY_LIST', page, filter],
		async () =>
			findFunctionRequest({
				pageNumber: page - 1,
				pageSize: 10,
				active: filter?.active,
				name: filter?.name,
				description: filter?.description,
				order: filter?.order,
			}),
		{ retry: false }
	);

	return { listFunctions };
}
