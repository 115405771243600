import api from '../../../services/api';
import { ChartDataProps } from '../components/Chart';

export async function findGraphByQuizIdRequest(
	id: number
): Promise<ChartDataProps> {
	const response = await api.get('/quiz/graph', {
		params: {
			quizId: id,
		},
	});

	return response.data;
}
