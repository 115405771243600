import axios, { AxiosError } from 'axios';

import cookies from '../utils/cookies';
import { ENV } from '../app/config/env';
import { ErrorResponse } from '../types/error.types';

const api = axios.create({
	baseURL: `${ENV.REACT_APP_BASE_API_URL}/api`,
	headers: {
		'Content-Type': 'application/json',
	},
});

api.interceptors.response.use(
	(response) => {
		return response;
	},
	(error: AxiosError<ErrorResponse>) => {
		const token = cookies.getCookie('@TOKEN');

		const businessCode = Number(error.response?.data?.businessCode);

		if (businessCode === 100 && token) {
			api.defaults.headers.common.jwt = token;
		}

		if ([100, 102, 105].includes(businessCode)) {
			delete api.defaults.headers.common.jwt;
			cookies.removeCookie('@TOKEN');
			cookies.removeCookie('@USER');
			window.location.href = `${window.location.origin}/auth`;
		}
		return Promise.reject(error);
	}
);

export default api;
