import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import {
	Accordion,
	Form,
	Header,
	Icon,
	Modal,
	Segment,
} from 'semantic-ui-react';
import { findGraphByQuizIdRequest } from '../../requests/findGraphByQuizIdRequest';
import { Chart } from '../../components/Chart';
import { useState } from 'react';
import { HiInformationCircle } from 'react-icons/hi';
import { findQuizInformationRequest } from '../../requests/findQuizInformationRequest';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Paragraph } from '../../../../components/Typography/Paragraph';
import {
	HeaderContent,
	FromTo,
	MessageStyled,
	FromToAndMessage,
	HeaderStyled,
	Label,
} from './styles';

export function QuizReportModal() {
	const { t } = useTranslation();
	const [activeIndex, setActiveIndex] = useState<number>();

	const { color } = useTheme();
	const navigate = useNavigate();

	const { quizId } = useParams<'quizId'>();

	const { data: quizGraph } = useQuery(
		[`QUIZ_GRAPH_${quizId}`],
		async () => findGraphByQuizIdRequest(Number(quizId)),
		{
			enabled: !!quizId,
			retry: false,
			refetchOnWindowFocus: false,
		}
	);

	const { data: quizInformation } = useQuery(
		[`QUIZ_INFORMATION_${quizId}`],
		async () => findQuizInformationRequest(Number(quizId)),
		{
			enabled: !!quizId,
			retry: false,
			refetchOnWindowFocus: false,
		}
	);

	const handleAcordionClick = (index: number) => {
		if (index === activeIndex) {
			return setActiveIndex(-1);
		}

		return setActiveIndex(index);
	};

	return (
		<Modal
			open
			size="large"
			onClose={() => navigate(-1)}
			closeIcon
			closeOnDimmerClick={false}
		>
			<Modal.Header>{t('page.quizzes.modal.quizReport.header')}</Modal.Header>

			<Modal.Content>
				<FromToAndMessage>
					<FromTo>
						<span>
							<Header as="h3" textAlign="center">
								<Header.Subheader
									content={t(
										'page.quizzes.modal.quizReport.content.fromTo.from'
									)}
								/>

								{quizInformation?.areaAndFunction.fromArea.name}

								<Header.Subheader>
									<Header as="h4">
										{quizInformation?.areaAndFunction.fromFunction.name}
									</Header>
								</Header.Subheader>
							</Header>
						</span>

						<span>
							<Icon
								id="arrowDown"
								name="arrow alternate circle down"
								size="large"
							/>
							<Icon
								id="arrowRight"
								name="arrow alternate circle right"
								size="large"
							/>
						</span>

						<span>
							<Header as="h3" textAlign="center">
								<Header.Subheader
									content={t('page.quizzes.modal.quizReport.content.fromTo.to')}
								/>

								{quizInformation?.areaAndFunction.toArea.name}

								<Header.Subheader>
									<Header as="h4">
										{quizInformation?.areaAndFunction.toFunction.name}
									</Header>
								</Header.Subheader>
							</Header>
						</span>
					</FromTo>

					<MessageStyled
						content={
							<>
								<p>
									{t(
										'page.quizzes.modal.quizReport.content.message.resultExplanation1'
									)}
								</p>
								<p>
									{t(
										'page.quizzes.modal.quizReport.content.message.resultExplanation2'
									)}
								</p>
								<p>
									{t(
										'page.quizzes.modal.quizReport.content.message.resultExplanation3'
									)}
								</p>
							</>
						}
					/>
				</FromToAndMessage>

				<Segment>
					<HeaderContent>
						<Header
							content={t(
								'page.quizzes.modal.quizReport.content.behaviorsByCompetence.header'
							)}
						/>

						<div style={{ marginBottom: 14 }}>
							<HiInformationCircle color={color.primary} size={18} />
							<Paragraph color="primary">
								{t(
									'page.quizzes.modal.quizReport.content.behaviorsByCompetence.informationBlindness'
								)}
							</Paragraph>
						</div>
					</HeaderContent>

					<Chart data={quizGraph} />

					<HeaderStyled>
						<Header.Content
							content={t(
								'page.quizzes.modal.quizReport.content.behaviorsByCompetence.totalJob'
							)}
						/>
						<Label circular size="big">
							{quizGraph?.simpleAverage}%
						</Label>
					</HeaderStyled>
				</Segment>

				<Segment>
					<Header
						content={t(
							'page.quizzes.modal.quizReport.content.quizResult.header'
						)}
					/>
					<Accordion>
						{quizInformation?.quizResult?.map(
							({ capacityLevel, behaviorsByCompetencies }, index) => (
								<>
									<Accordion.Title
										active={activeIndex === index}
										content={capacityLevel}
										onClick={() => handleAcordionClick(index)}
									/>
									<Accordion.Content active={activeIndex === index}>
										<Form>
											<Form.Group grouped>
												{behaviorsByCompetencies.length ? (
													behaviorsByCompetencies?.map(
														({ competence, behaviors }) => (
															<Segment key={competence}>
																<Header as="h5" content={competence} />

																{behaviors?.map(({ checked, description }) => (
																	<Header
																		key={description}
																		as="h5"
																		icon={
																			checked
																				? 'check circle'
																				: 'circle outline'
																		}
																		color={checked ? 'green' : undefined}
																		subheader={description}
																	/>
																))}
															</Segment>
														)
													)
												) : (
													<Segment>
														<Header as="h5" icon="dont" content="No Results" />
													</Segment>
												)}
											</Form.Group>
										</Form>
									</Accordion.Content>
								</>
							)
						)}
					</Accordion>
				</Segment>
			</Modal.Content>
		</Modal>
	);
}
