import styled from 'styled-components';
import wingfootLogo from '../../assets/images/wingfoot-logo-yellow.png';

interface IFilterbarProps {
	show: boolean;
}

export const Container = styled.div<IFilterbarProps>`
	background-color: #fff;
	width: 290px;
	margin-top: 0px;
	border-radius: 5px;
	box-shadow: -2px 0px 8px -2px #00000025;

	position: fixed;
	top: 197px;
	right: ${({ show, theme }) => (show ? `${theme.spacing[2]}px` : '-300px')};

	transition: ${({ theme }) => theme.transition.fast};
	z-index: 2;

	form {
		max-height: 60vh;
		display: flex;
		flex-direction: column;

		.filter__submit {
			position: relative;
			bottom: 0;
		}
	}

	@media (max-width: 460px) {
		height: 100%;
		width: 100vw;
		margin-top: 0;
		padding: 0;
		border-radius: 0;
		display: ${({ show }) => (show ? 'flex' : 'none')};
		flex-direction: column;
		position: absolute;
		top: 0;
		right: 0;
		z-index: 99;

		form {
			min-height: none;
			height: 100vh;
			max-height: none;
			position: relative;
			top: 0;
			bottom: 0;
		}
	}
`;

export const Logo = styled.img`
	height: 30px;
	content: url(${wingfootLogo});
	display: block;
`;

export const FilterHeader = styled.div`
	.filter__logo {
		display: none;
	}

	.filter__title {
		font-size: 1.4em;
		font-weight: bold;

		padding: 8px 16px;
		border-bottom: 1px solid #f2f2f2;
		opacity: 0.8;
	}

	.filter__close {
		display: none;
	}

	@media (max-width: 460px) {
		background-color: #004ea8;
		height: 56px;
		padding: 0 20px;

		display: flex;
		justify-content: space-between;
		align-items: center;

		.filter__logo {
			display: block;
		}
		.filter__title {
			color: #fff;
			border-bottom: 0;
			opacity: 1;
		}
		.filter__close {
			display: block;
			color: ${({ theme }) => theme.color.secondary};
		}
	}
`;

export const FilterBody = styled.div`
	min-height: 40vh;
	padding: ${({ theme }) => `${theme.spacing[2]}px`};

	overflow-y: auto;

	&::-webkit-scrollbar-track {
		background: none;
	}
	&::-webkit-scrollbar {
		width: 4px;
		scroll-padding-top: 100px;
	}

	@media (max-width: 460px) {
		height: 100%;
		flex: 1;
	}
`;

export const FilterSubmit = styled.div`
	position: relative;
	bottom: 0;

	@media (max-width: 460px) {
		position: relative;
		bottom: 0;
		padding: 16px;

		border-top: 1px solid ${({ theme }) => theme.color.gray_light};
	}
`;
