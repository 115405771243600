/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Link } from 'react-router-dom';

import { Icon, Dropdown, Popup } from 'semantic-ui-react';

import {
	Container,
	ApplicationName,
	UserProfile,
	Icons,
	Profile,
	Logo,
	SidebarButton,
	AppName,
} from './styles';

import { useAuth } from '../../../features/authentication/hooks/useAuth';
import { LanguageLocationEnum } from '../../../app/locales';
import { useTranslation } from 'react-i18next';
import { LineLoader } from '../../LineLoader';

interface Props {
	handleShow: () => void;
}

function Header({ handleShow }: Props) {
	// @ts-ignore
	const { i18n, t } = useTranslation();
	const { user, signOut } = useAuth();

	const handleChangeLanguage = (language: LanguageLocationEnum) => {
		i18n.changeLanguage(language);
		localStorage.setItem('@LANGUAGE', i18n.language);
	};

	return (
		<Container>
			<SidebarButton onClick={handleShow}>
				<Icon name="bars" />
			</SidebarButton>

			<ApplicationName>
				<Link to="/">
					<Logo />
					{/* @ts-ignore */}
					<AppName>{t('application.name')}</AppName>
				</Link>
			</ApplicationName>

			<UserProfile>
				<Icons>
					<Popup
						content="Language"
						trigger={
							<Dropdown
								trigger={
									<Link to="#">
										<Icon name="globe" />
									</Link>
								}
								icon={null}
								pointing="top right"
							>
								<Dropdown.Menu>
									<Dropdown.Item
										flag="us"
										text="English"
										active={['en', 'en-US'].includes(i18n.language)}
										onClick={() =>
											handleChangeLanguage(LanguageLocationEnum.EN_US)
										}
									/>
									<Dropdown.Item
										flag="br"
										text="Português"
										active={['pt-BR', 'PT'].includes(i18n.language)}
										onClick={() =>
											handleChangeLanguage(LanguageLocationEnum.PT_BR)
										}
									/>
								</Dropdown.Menu>
							</Dropdown>
						}
						position="right center"
					/>
				</Icons>

				{user ? (
					<Dropdown
						trigger={
							<Profile>
								<div>
									<span>
										{user?.firstName} {user?.lastName}
									</span>
									<span>{user?.userId}</span>
								</div>

								<img
									src={
										user?.photoBase64
											? `data:image/png;base64,${user?.photoBase64}`
											: `https://ui-avatars.com/api/?rounded=true&background=random&color=random&name=${user?.firstName}${user?.lastName}`
									}
									alt="profile_photo"
								/>
							</Profile>
						}
						icon={null}
						pointing="top right"
					>
						<Dropdown.Menu>
							<Dropdown.Item
								text={t('label.userGuides')}
								onClick={() => undefined}
							/>
							<Dropdown.Item text={t('label.logout')} onClick={signOut} />
						</Dropdown.Menu>
					</Dropdown>
				) : (
					<LineLoader qty={2} lengths={[120, 90]} />
				)}
			</UserProfile>
		</Container>
	);
}

export { Header };
