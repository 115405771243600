import { useMutation } from '@tanstack/react-query';
import { findMyQuizRequest } from '../requests/findMyQuizRequest';
import { Alert } from '../../../components/Alert';
import { AxiosError } from 'axios';
import {
	MessageProps,
	SearchQuizResponseMessage,
} from '../pages/SelfAssessmentFormModal';

type Props = {
	watchTargetSubareaId: number;
	watchTargetFunctionId: number;
	setMessage: React.Dispatch<React.SetStateAction<MessageProps>>;
};

export function useSearchQuiz({
	watchTargetSubareaId,
	watchTargetFunctionId,
	setMessage,
}: Props) {
	const searchQuiz = useMutation({
		mutationFn: async () =>
			findMyQuizRequest({
				toSubAreaId: watchTargetSubareaId,
				toFunctionId: watchTargetFunctionId,
			}),
		onSuccess: (data) => {
			if (!data.length) {
				setMessage({
					icon: 'dont',
					content: SearchQuizResponseMessage.DataNotFound,
				});
				Alert({ message: 'Target Function was not found', type: 'warning' });
			} else {
				Alert({ message: 'Research completed', type: 'success' });
			}
		},
		onError: (error: AxiosError) => {
			Alert({ message: error.message, type: 'error' });
		},
		retry: false,
	});

	return {
		searchQuiz,
	};
}
