import styled from 'styled-components';
import {
	Table as TableSemantic,
	TableHeader as TableHeaderSemantic,
	TableBody as TableBodySemantic,
	TableRow as TableRowSemantic,
	TableHeaderCell as TableHeaderCellSemantic,
	TableCell as TableCellSemantic,
} from 'semantic-ui-react';

type TableWrapperProps = {
	paddedBottom?: boolean;
	capacityLevelOptionsLength?: number;
};

const DROPDOWN_ITEM_HEIGHT = '37px';

export const TableWrapper = styled.div<TableWrapperProps>`
	max-height: ${({ paddedBottom }) => (paddedBottom ? 'auto' : '60vh')};
	overflow: auto;

	&::-webkit-scrollbar {
		width: 2px;
		scroll-padding-top: 100px;
	}

	&::-webkit-scrollbar-track {
		background: #fff;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #333333 !important;
	}

	@media (min-width: 1920px) {
		padding-bottom: ${({ paddedBottom, capacityLevelOptionsLength }) =>
			paddedBottom &&
			`calc(${
				capacityLevelOptionsLength && capacityLevelOptionsLength > 8
					? 8
					: capacityLevelOptionsLength
			} * ${DROPDOWN_ITEM_HEIGHT})`};
	}

	@media (max-width: 1919px) {
		padding-bottom: ${({ paddedBottom, capacityLevelOptionsLength }) =>
			paddedBottom &&
			`calc(${
				capacityLevelOptionsLength && capacityLevelOptionsLength > 6
					? 6
					: capacityLevelOptionsLength
			} * ${DROPDOWN_ITEM_HEIGHT})`};
	}

	@media (max-width: 991px) {
		padding-bottom: ${({ paddedBottom, capacityLevelOptionsLength }) =>
			paddedBottom &&
			`calc(${
				capacityLevelOptionsLength && capacityLevelOptionsLength > 4
					? 4
					: capacityLevelOptionsLength
			} * ${DROPDOWN_ITEM_HEIGHT})`};
	}

	@media (max-width: 767px) {
		padding-bottom: ${({ paddedBottom }) =>
			paddedBottom && `calc(3 * ${DROPDOWN_ITEM_HEIGHT})`};
	}
`;

export const Table = styled(TableSemantic)`
	margin-top: 0 !important;
	outline: 0 !important;
	border: 0 !important;
	border-collapse: separate;
	box-shadow: 0 5px 3px -3px rgba(0, 0, 0, 0.1) !important;

	a {
		font-size: 1rem;
	}
`;

export const TableHeader = styled(TableHeaderSemantic)`
	background-color: none !important;
	position: sticky;
	top: 0;

	z-index: 1;

	tr {
		th {
			background-color: #333333 !important;
			color: #ffffff !important;
			font-weight: 600 !important;
		}
		.action {
			background-color: #333333;
		}
	}
`;

export const TableBody = styled(TableBodySemantic)`
	color: #000000;

	&:nth-child(odd) {
		background-color: #f2f2f2 !important;
	}

	&:hover td[rowspan],
	tr:hover td {
		background-color: #e1e1e1;
	}

	tr {
		height: 4rem;

		.rowspan {
			&:nth-child(even)[rowspan] {
				background-color: #f2f2f2 !important;
			}
		}

		.table-description {
			max-width: 600px;
		}
	}
`;

export const TablePagination = styled.div`
	background-color: #ffffff !important;
	width: 100%;
	padding: 16px 24px;
	border-radius: 0 0 5px;
	box-shadow: 0 -2px 10px -4px #00000020;

	display: flex;
	justify-content: center;

	a {
		font-size: 12px;
	}

	@media (max-width: 460px) {
		padding: 8px 16px;
	}
`;

export const TableRow = styled(TableRowSemantic)`
	&:nth-child(even) {
		background-color: #f2f2f2;

		td {
			background-color: #f2f2f2;
		}
	}
`;

interface ITableHeaderCellProps {
	isIdColumn?: boolean;
	isActionColumn?: boolean;
	isStickyColumn?: boolean;
}

export const TableHeaderCell = styled(
	TableHeaderCellSemantic
)<ITableHeaderCellProps>`
	border: 0 !important;
	white-space: nowrap;
	overflow: hidden;

	${({ isIdColumn }) => isIdColumn && 'width: 50px'}

	${({ isActionColumn }) =>
		isActionColumn &&
		`		
			width: 50px;
			padding: 10px 8px;
		
			position: sticky;
			right: 0;
		
			box-shadow: -3px 0 4px #00000015 !important;
		`}

	${({ isStickyColumn }) =>
		isStickyColumn &&
		`
		box-shadow: 3px 0 4px #00000015 !important;

		position: sticky;
		left: 0
	`}
`;

interface ITableCellProps {
	isIdColumn?: boolean;
	isActionColumn?: boolean;
	isStickyColumn?: boolean;
}

export const TableCell = styled(TableCellSemantic)<ITableCellProps>`
	border: 0 !important;
	/* white-space: nowrap; */

	${({ isIdColumn }) => isIdColumn && 'width: 50px'}

	${({ isActionColumn }) =>
		isActionColumn &&
		`
			background-color: #ffffff;
			width: 50px;
			text-align: center !important;
			padding: 10px 8px;
			border: 0 !important;
		
			position: sticky;
			right: 0;
		
			box-shadow: -3px 0 4px #00000015 !important;
		
			.actionColumn__buttonGroup {
				padding: 4px 8px;
				display: flex;
				flex-direction: row;
				gap: 4px !important;
			}
		`}
		
	${({ isStickyColumn }) =>
		isStickyColumn &&
		`
		background-color: #ffffff;
		box-shadow: 3px 0 4px #00000015 !important;

		position: sticky;
		left: 0;

		z-index: 1;
	`}
`;

export const TableHeaderCellAction = styled(TableHeaderCellSemantic)`
	background-color: #333333;
	width: 50px;
	text-align: center;
	padding: 10px 8px;
	border-left: 1px solid #f2f2f2;

	position: sticky;
	right: 0;
`;
