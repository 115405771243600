import api from '../../../services/api';
import { PageableResponse } from '../../../types/PageableResponse.types';
import {
	FilterListFunctions,
	ListFunctionResponse,
} from '../types/function.type';

type Props = FilterListFunctions & {
	pageNumber?: number;
	pageSize?: number;
};

export async function findFunctionRequest({
	active,
	name,
	description,
	order,
	pageNumber,
	pageSize,
}: Props): Promise<PageableResponse<ListFunctionResponse>> {
	const response = await api.get('/function', {
		params: {
			active: active ?? undefined,
			name: name || undefined,
			description: description ?? undefined,
			order: order ?? undefined,

			page: pageNumber,
			size: pageSize,
		},
	});

	return response.data;
}
