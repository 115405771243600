import api from '../../../services/api';
import { PageableResponse } from '../../../types/PageableResponse.types';
import {
	FilterListSelfAssessments,
	ListQuizResponseProps,
} from '../types/quiz.type';

type Props = FilterListSelfAssessments & {
	userId?: string;
	pageNumber?: number;
	pageSize?: number;
};

export async function findMyQuizzesRequest({
	userId,
	userName,
	createdAt,
	fromAreaId,
	fromFunctionId,
	toAreaId,
	toFunctionId,

	pageNumber,
	pageSize,
}: Props): Promise<PageableResponse<ListQuizResponseProps>> {
	const response = await api.get('/quiz', {
		params: {
			userId: userId || undefined,
			userName: userName || undefined,
			createdAt: createdAt || undefined,
			fromAreaId: fromAreaId || undefined,
			fromFunctionId: fromFunctionId || undefined,
			toAreaId: toAreaId || undefined,
			toFunctionId: toFunctionId || undefined,

			page: pageNumber ?? undefined,
			size: pageSize ?? undefined,
		},
	});

	return response.data;
}
