function setCookie(cname: string, cvalue: string, exdays: number) {
	const d = new Date();
	d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
	const expires = 'expires=' + d.toUTCString();
	document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}

function getCookie(cname: string) {
	const name = cname + '=';
	const decodedCookie = decodeURIComponent(document.cookie);
	const ca = decodedCookie.split(';');
	for (const element of ca) {
		let c = element;
		while (c.startsWith(' ')) {
			c = c.substring(1);
		}
		if (c.startsWith(name)) {
			return c.substring(name.length, c.length);
		}
	}
	return '';
}

function removeCookie(cname: string) {
	const d = new Date('1/1/1970');
	d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
	const expires = 'expires=' + d.toUTCString();
	document.cookie = cname + '=' + 'null' + ';' + expires + ';path=/';
}

export default {
	setCookie,
	getCookie,
	removeCookie,
};
