import { Title } from '../../../../components/Typography/Title';
import { Toolbar } from '../../../../components/Toolbar';
import { Table } from '../../../../components/Table';
import { Container } from './styles';
import {
	Button,
	Dropdown,
	Form,
	Icon,
	PaginationProps,
	Popup,
} from 'semantic-ui-react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useDeleteAnswerKey } from '../../hooks/useDeleteAnswerKey';
import { FilterGroup } from '../../../../components/Form/FilterGroup';
import { Controller, useForm } from 'react-hook-form';
import { useFindAnswerKeys } from '../../hooks/useFindAnswerKeys';
import { NoResultsTableInformation } from '../../../../components/TableNoDataFound';
import { useTranslation } from 'react-i18next';
import { ModalConfirm } from '../../../../components/ModalConfirm';

export type FilterListAnswerKeys = {
	areaName?: string;
	subareaName?: string;
};

export function ListAnswerKeys() {
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [item, setItem] = useState<number>();
	const { t } = useTranslation();
	const { control, handleSubmit, reset } = useForm<FilterListAnswerKeys>();
	const [page, setPage] = useState(1);
	const [filter, setFilter] = useState<FilterListAnswerKeys>();

	const navigate = useNavigate();
	const location = useLocation();

	const { deleteAnswerKey } = useDeleteAnswerKey();
	const { listAnswerKeys } = useFindAnswerKeys({ page, filter });

	const handlePageChange = (
		_: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
		data: PaginationProps
	) => {
		setPage(Number(data.activePage));
	};

	const onSubmitFilter = (data: FilterListAnswerKeys) => {
		setFilter(data);
		setPage(1);
	};

	const handleResetFilter = () => {
		reset({
			areaName: '',
			subareaName: '',
		});
		setFilter({
			areaName: undefined,
			subareaName: undefined,
		});
	};

	return (
		<Container>
			<Title>{t('title.answerKeys')}</Title>

			<Toolbar
				handleAdd={() => navigate(`${location.pathname}/create`)}
				filter={{
					content: (
						<FilterGroup>
							<Controller
								control={control}
								name="areaName"
								render={({ field: { value, onChange } }) => (
									<Form.Input
										label={t('page.answerKeys.area')}
										value={value}
										onChange={onChange}
									/>
								)}
							/>
							<Controller
								control={control}
								name="subareaName"
								render={({ field: { value, onChange } }) => (
									<Form.Input
										label={t('page.answerKeys.subarea')}
										value={value}
										onChange={onChange}
									/>
								)}
							/>
						</FilterGroup>
					),
					onApply: handleSubmit(onSubmitFilter),
					onClear: handleResetFilter,
				}}
			></Toolbar>

			<span>
				<Table>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell content="ID" isIdColumn />
							<Table.HeaderCell content={t('page.answerKeys.area')} />
							<Table.HeaderCell content={t('page.answerKeys.subarea')} />

							<Table.HeaderCell isActionColumn>
								<Icon name="cog" size="large" />
							</Table.HeaderCell>
						</Table.Row>
					</Table.Header>

					<Table.Body>
						{listAnswerKeys?._embedded?.templates?.map(
							({ id, subAreaResponse }, index) => (
								<Table.Row key={id}>
									<Table.Cell content={index + 1} />
									<Table.Cell content={subAreaResponse.area.name} />
									<Table.Cell content={subAreaResponse.name} />
									<Table.Cell isActionColumn>
										<Dropdown
											pointing="right"
											trigger={<Icon name="ellipsis vertical" size="large" />}
											icon={null}
										>
											<Dropdown.Menu>
												<span className="actionColumn__buttonGroup">
													<Popup
														content={t('tooltip.edit')}
														trigger={
															<Button
																icon="pencil"
																size="mini"
																color="yellow"
																onClick={() =>
																	navigate(
																		`${location.pathname}/update/${subAreaResponse.id}`
																	)
																}
															/>
														}
													/>

													<Popup
														content={t('tooltip.delete')}
														trigger={
															<Button
																icon="trash"
																size="mini"
																color="red"
																onClick={() => {
																	setItem(subAreaResponse.id);
																	setOpenModal(true);
																}}
															/>
														}
													/>
												</span>
											</Dropdown.Menu>
										</Dropdown>
									</Table.Cell>
								</Table.Row>
							)
						)}
					</Table.Body>
				</Table>

				{listAnswerKeys?._embedded?.templates?.length ? (
					<Table.Pagination
						page={page}
						activePage={page}
						totalPages={listAnswerKeys?.page.totalPages ?? 0}
						boundaryRange={1}
						siblingRange={0}
						onPageChange={handlePageChange}
						size="mini"
						firstItem={null}
						lastItem={null}
						secondary
					/>
				) : (
					<NoResultsTableInformation
						icon="dont"
						title={t('message.noDataFound.title')}
						subTitle={t('message.noDataFound.subTitle')}
					/>
				)}
			</span>
			<Outlet />

			<ModalConfirm
				open={openModal}
				onClose={() => setOpenModal(false)}
				onOpen={() => setOpenModal(true)}
				title={t('component.modal.header.confirmation')}
				description={t('component.modal.content.AreYouSureYouWantToDelete')}
				type="remove"
				size="mini"
				onConfirm={() => {
					item && deleteAnswerKey.mutateAsync(item);
					setOpenModal(false);
				}}
				onCancel={() => setOpenModal(false)}
			/>
		</Container>
	);
}
