import { Button as ButtonSemantic, ButtonProps } from 'semantic-ui-react';
import styled from 'styled-components';

export const Button = styled(ButtonSemantic)<ButtonProps>`
	background-color: ${({ theme, active }) =>
		active
			? `${theme.color.primary_dark} !important`
			: `${theme.color.primary} !important`};

	color: ${({ theme }) => `${theme.color.secondary} !important`};

	&:hover {
		background-color: ${({ theme }) =>
			`${theme.color.primary_dark} !important`};

		transition: ${({ theme }) => theme.transition.fast};
	}
`;
