import { useMutation } from '@tanstack/react-query';
import { createCompetenceRequest } from '../requests/createCompetenceRequest';
import { useNavigate } from 'react-router-dom';
import { Alert } from '../../../components/Alert';
import { queryClient } from '../../../app/App';
import { CompetenceFormProps } from '../types/competence.type';
import { AxiosError } from 'axios';

export function useCreateCompetence() {
	const navigate = useNavigate();

	const createCompetence = useMutation({
		mutationFn: async (data: CompetenceFormProps) => {
			return createCompetenceRequest(data);
		},
		onSuccess: () => {
			Alert({ message: 'Successfully created!', type: 'success' });
			queryClient.invalidateQueries(['COMPETENCE_LIST']);
			navigate(-1);
		},
		onError: (error: AxiosError) => {
			Alert({ message: error.message, type: 'error' });
		},
	});

	return { createCompetence };
}
