import { useNavigate } from 'react-router-dom';

import { Button } from '../../components/Button';

import { Container, Title, Subtitle, Description } from './styles';
import { useTranslation } from 'react-i18next';

function PageNotFound() {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const handleRedirect = () => navigate('/');

	return (
		<Container>
			<Title>404</Title>
			<Subtitle>{t('title.pageNotFound')}</Subtitle>
			<Description>{t('page.pageNotFound.content.description')}</Description>

			<Button
				title={t('page.pageNotFound.button.goToHome')}
				onClick={handleRedirect}
			/>
		</Container>
	);
}

export default PageNotFound;
