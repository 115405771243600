import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { editCompetenceRequest } from '../requests/editCompetenceRequest';
import { Alert } from '../../../components/Alert';
import { queryClient } from '../../../app/App';
import { CompetenceFormProps } from '../types/competence.type';
import { AxiosError } from 'axios';

export function useEditCompetence() {
	const navigate = useNavigate();

	const editCompetence = useMutation({
		mutationFn: async (data: CompetenceFormProps) => {
			return editCompetenceRequest(data);
		},
		onSuccess: () => {
			Alert({ message: 'Successfully edited!', type: 'success' });
			queryClient.invalidateQueries(['COMPETENCE_LIST']);
			navigate(-1);
		},
		onError: (error: AxiosError) => {
			Alert({ message: error.message, type: 'error' });
		},
	});

	return { editCompetence };
}
