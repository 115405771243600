import { Placeholder } from 'semantic-ui-react';

type IProps = {
	qty?: number;
	lengths?: number[];
};

export function LineLoader({ qty, lengths }: IProps) {
	return (
		<div
			style={{
				height: 40,
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-around',
				alignItems: 'flex-end',
				overflow: 'none',
			}}
		>
			{qty ? (
				Array(qty)
					.fill('_')
					.map((_, index) => (
						<Placeholder
							key={index}
							style={{
								height: 10,
								width: lengths?.[index] ?? 120,
								marginTop: 0,
							}}
						/>
					))
			) : (
				<Placeholder
					style={{ height: 10, width: lengths?.[0] ?? '100%', marginTop: 0 }}
				/>
			)}
		</div>
	);
}
