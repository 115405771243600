import { AxiosError } from 'axios';
import api from '../../../services/api';

type Props = {
	name: string;
};

export async function createAreaRequest({ name }: Props) {
	try {
		const response = await api.post('/area', {
			name,
		});

		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			throw new Error(error.response?.data?.message);
		} else {
			throw new Error();
		}
	}
}
