import { useQuery } from '@tanstack/react-query';
import { findCapacityLevelRequest } from '../requests/findCapacityLevelRequest';
import { FilterListCapacityLevels } from '../types/capacityLevel.type';

type UseFindCapacityLevels = {
	page: number;
	filter?: FilterListCapacityLevels;
};

export function useFindCapacityLevels({ page, filter }: UseFindCapacityLevels) {
	const { data: listCapacityLevels } = useQuery(
		['CAPACITY_LEVEL_LIST', page, filter],
		async () =>
			findCapacityLevelRequest({
				name: filter?.name,
				active: filter?.active,

				pageNumber: page - 1,
				pageSize: 10,
			}),
		{ retry: false }
	);

	return { listCapacityLevels };
}
