import api from '../../../services/api';
import { PageableResponse } from '../../../types/PageableResponse.types';
import { ListAreaResponse } from '../types/area.type';

interface IProps {
	name?: string;

	pageNumber?: number;
	pageSize?: number;
}

export async function findAreaRequest({
	name,
	pageNumber,
	pageSize,
}: IProps): Promise<PageableResponse<ListAreaResponse>> {
	const response = await api.get('/area', {
		params: {
			name: name || undefined,
			page: pageNumber ?? undefined,
			size: pageSize ?? undefined,
		},
	});

	return response.data;
}
