import { AxiosError } from 'axios';
import api from '../../../services/api';
import { CapacityLevelFormProps } from '../types/capacityLevel.type';

export async function editCapacityLevelRequest({
	...rest
}: CapacityLevelFormProps) {
	try {
		const response = await api.put('/capacityLevel', {
			...rest,
		});

		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			throw new Error(error.response?.data?.message);
		} else {
			throw new Error();
		}
	}
}
