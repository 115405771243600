import styled from 'styled-components';
import wingfootLogo from '../../../assets/images/wingfoot-logo-yellow.png';
import goodyearLogo from '../../../assets/images/goodyear-logo.png';

export const Container = styled.header`
	background: ${({ theme }) => theme.color.primary};

	position: fixed;
	top: 0;

	display: flex;
	justify-content: space-between;
	align-items: center;

	width: 100%;
	height: 56px;

	padding: 0 20px;

	border-bottom: 6px solid ${({ theme }) => theme.color.secondary};

	z-index: 31;

	@media (max-width: 460px) {
		padding: 0 10px;

		position: fixed;
		top: 0;
		left: 0;
	}
`;

export const SidebarButton = styled.button`
	display: none;
	margin-right: 14px;

	background-color: transparent;
	border: none;
	cursor: pointer;

	i {
		font-size: 18px;
		color: ${({ theme }) => theme.color.secondary};
	}

	@media (max-width: 460px) {
		display: block;
	}
`;

export const ApplicationName = styled.div`
	display: flex;

	a {
		display: flex;
		align-items: center;
		gap: 20px;
	}

	@media (max-width: 460px) {
		a {
			gap: 10px;
		}
	}
`;

export const Logo = styled.img`
	content: url(${goodyearLogo});

	width: calc(180px - 40px);

	@media (max-width: 460px) {
		content: url(${wingfootLogo});
		margin-left: 0;
		width: 26px;
	}
`;

export const AppName = styled.span`
	color: ${({ theme }) => theme.color.white};
	font-weight: bold;
`;

export const UserProfile = styled.div`
	display: flex;
	justify-content: flex-end;
`;

export const Icons = styled.div`
	display: flex;
	align-items: center;

	margin-right: 30px;

	a {
		margin: 0 9px;

		i {
			font-size: 20px;
			color: ${({ theme }) => theme.color.white};
		}
	}

	@media (max-width: 460px) {
		display: none;
	}
`;

export const Profile = styled.div`
	display: flex;
	align-items: center;

	div {
		display: flex;
		flex-direction: column;

		text-align: right;

		span {
			color: ${({ theme }) => theme.color.white};
		}
	}

	img {
		width: 40px;
		height: 40px;

		margin-left: 8px;

		border-radius: 20px;
		border: 1px solid ${({ theme }) => theme.color.secondary};
	}

	@media (max-width: 460px) {
		div {
			display: none;
		}
	}
`;
