import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { editBehaviorRequest } from '../requests/editBehaviorRequest';
import { Alert } from '../../../components/Alert';
import { queryClient } from '../../../app/App';
import { AxiosError } from 'axios';

export function useEditBehavior() {
	const navigate = useNavigate();

	const editBehavior = useMutation({
		mutationFn: async (data: any) => {
			return editBehaviorRequest(data);
		},
		onSuccess: () => {
			Alert({ message: 'Successfully edited!', type: 'success' });
			queryClient.invalidateQueries(['BEHAVIOR_LIST']);
			navigate(-1);
		},
		onError: (error: AxiosError) => {
			Alert({ message: error.message, type: 'error' });
		},
	});

	return { editBehavior };
}
