import api from '../../../services/api';
import { PageableResponse } from '../../../types/PageableResponse.types';
import {
	FilterListCompetenciesProps,
	ListCompetenceResponseProps,
} from '../types/competence.type';

type Props = FilterListCompetenciesProps & {
	pageNumber?: number;
	pageSize?: number;
};

export async function findCompetenceRequest({
	areaId,
	title,
	description,
	active,

	pageNumber,
	pageSize,
}: Props): Promise<PageableResponse<ListCompetenceResponseProps>> {
	const response = await api.get('/competence', {
		params: {
			areaId: areaId,
			title: title,
			description: description,
			active: active ?? true,

			page: pageNumber,
			size: pageSize,
		},
	});

	return response.data;
}
