/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useNavigate, useParams } from 'react-router-dom';
import { Dropdown, Form, Message, Modal, Popup } from 'semantic-ui-react';
import { ButtonGDY } from '../../../../components/Form/ButtonGDY';
import { useQuery } from '@tanstack/react-query';
import { findAnswerKeyByIdRequest } from '../../requests/findAnswerKeyByIdRequest';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useCreateAnswerKey } from '../../hooks/useCreateAnswerKey';
import { useEditAnswerKey } from '../../hooks/useEditAnswerKey';
import {
	AnswerKeyFormProps,
	AnswerKeyFormRequestProps,
} from '../../types/template.type';
import { useEffect } from 'react';
import { Table } from '../../../../components/Table';
import { useAnswerKeyFormData } from '../../hooks/useAnswerKeyFormData';
import { FormDropdown } from '../../../../components/Form/FormDropdown';
import { PopupRotateInfo, RotateDeviceIcon } from './styles';
import { useTranslation } from 'react-i18next';
import { FormAsyncDropdown } from '../../../../components/Form/DropdownInfinityScroll';

export function AnswerKeyFormModal() {
	// @ts-ignore
	const { t } = useTranslation();
	const {
		control,
		formState: { errors },
		handleSubmit,
		reset,
		setValue,
	} = useForm<AnswerKeyFormProps>();

	const navigate = useNavigate();

	const { subareaId } = useParams<'subareaId'>();

	const { createAnswerKey } = useCreateAnswerKey();
	const { editAnswerKey } = useEditAnswerKey();

	const watchAreaId = useWatch({ control, name: 'areaId' });
	const watchSubareaId = useWatch({ control, name: 'subAreaId' });
	const watchCompetencies = useWatch({ control, name: 'competencies' });
	const watchFunctionId = useWatch({ control, name: 'functionId' });

	const watchAnswerKey = useWatch({ control, name: 'template' });

	const {
		functions,
		areaOptions,
		subareaOptions,
		loadCompetenceOptions,
		functionOptions,
		capacityLevelOptions,
	} = useAnswerKeyFormData({ areaId: watchAreaId, subareaId: watchSubareaId });

	const selectedFunctions = functions?.filter((item) =>
		watchFunctionId?.includes(item.id)
	);

	useQuery(
		['TEMPLATE_BY_ID', subareaId],
		async () => {
			const data = await findAnswerKeyByIdRequest(Number(subareaId));

			reset({
				areaId: data[0].subAreaResponse.area.id,
				subAreaId: data[0].subAreaResponse.id,
				competencies: data.map(({ competence }) => ({
					value: competence.id,
					label: competence.title,
					...competence,
				})),
				functionId: data[0].details.map(({ function: { id } }) => id),

				template: data.map(
					({ subAreaResponse, competence, details, id: templateId }) => ({
						id: templateId,
						subAreaId: subAreaResponse.id,
						competenceId: competence.id,
						details: details.map(
							({
								id: detailId,
								function: { id: functionId },
								capacityLevel,
							}) => ({
								id: detailId,
								functionId: functionId,
								capacityLevelId: capacityLevel.id,
							})
						),
					})
				),
			});
		},
		{
			enabled: !!subareaId,
			retry: false,
			refetchOnWindowFocus: false,
		}
	);

	useEffect(() => {
		const updateAnswerKeyStructure = () => {
			setValue(
				'template',
				watchCompetencies?.map((competenceItem, competenceIndex) => ({
					id: competenceItem.id,
					subAreaId: watchSubareaId,
					competenceId: competenceItem.id,

					details: selectedFunctions?.map((functionItem, functionIndex) => ({
						functionId: functionItem.id,
						capacityLevelId:
							watchAnswerKey?.[competenceIndex]?.details?.[functionIndex]
								?.capacityLevelId ?? null,
					})),
				})) as AnswerKeyFormRequestProps[]
			);
		};

		if (selectedFunctions && watchCompetencies.length) {
			updateAnswerKeyStructure();
		}
	}, [watchCompetencies, watchFunctionId]);

	const onSubmit = (data: AnswerKeyFormProps) => {
		if (subareaId && data.areaId) {
			return editAnswerKey.mutateAsync(data.template);
		}

		return createAnswerKey.mutateAsync(data.template);
	};

	return (
		<Modal
			open
			size="large"
			onClose={() => navigate(-1)}
			closeIcon
			closeOnDimmerClick={false}
		>
			<Modal.Header>{t('page.answerKeys.templateRegistration')}</Modal.Header>

			<Modal.Content>
				<Form>
					<FormDropdown<AnswerKeyFormProps>
						control={control}
						name="areaId"
						label={t('page.answerKeys.area')}
						options={areaOptions ?? []}
						required
					/>

					<FormDropdown<AnswerKeyFormProps>
						control={control}
						name="subAreaId"
						label={t('page.answerKeys.subarea')}
						options={subareaOptions ?? []}
						required
						disabled={!watchAreaId}
					/>

					<FormAsyncDropdown<AnswerKeyFormProps>
						control={control}
						name="competencies"
						label={t('page.answerKeys.competencies')}
						loadOptions={loadCompetenceOptions as any}
						disabled={!watchAreaId}
						required
					/>

					<FormDropdown<AnswerKeyFormProps>
						control={control}
						name="functionId"
						label={t('page.answerKeys.functions')}
						options={functionOptions ?? []}
						multiple
						required
						disabled={!watchAreaId}
					/>

					{!!watchCompetencies?.length && !!selectedFunctions?.length && (
						<Form.Field required>
							<label>{t('page.answerKeys.template')}</label>
							<PopupRotateInfo>
								<Popup
									inverted
									size="small"
									content={t('page.answerKeys.modal.rotateDeviceInformation')}
									trigger={
										<span>
											<RotateDeviceIcon size={18} />
										</span>
									}
								/>
							</PopupRotateInfo>
							<Table
								compact
								paddedBottom={true}
								capacityLevelOptionsLength={capacityLevelOptions?.length}
							>
								<Table.Header>
									<Table.Row>
										<Table.HeaderCell
											isStickyColumn
											content={t('page.answerKeys.competence')}
										/>

										{selectedFunctions?.map((item) => (
											<Table.HeaderCell key={item.id} content={item.name} />
										))}
									</Table.Row>
								</Table.Header>

								<Table.Body>
									{watchCompetencies?.map((competenceItem, competenceIndex) => {
										return (
											<Table.Row key={competenceItem.id}>
												<Table.Cell
													isStickyColumn
													content={competenceItem.title}
													singleLine
												/>

												{selectedFunctions?.map(
													(functionItem, functionIndex) => {
														return (
															<Table.Cell key={functionItem.id}>
																<Controller
																	control={control}
																	name={`template.${competenceIndex}.details.${functionIndex}.capacityLevelId`}
																	rules={{ required: true }}
																	render={({ field: { value, onChange } }) => (
																		<Dropdown
																			placeholder={t(
																				'page.answerKeys.capacityLevel'
																			)}
																			selection
																			options={capacityLevelOptions ?? []}
																			value={value}
																			onChange={(_, data) =>
																				onChange(data.value)
																			}
																			error={
																				!!errors.template?.[competenceIndex]
																					?.details?.[functionIndex]
																					?.capacityLevelId
																			}
																		/>
																	)}
																/>
															</Table.Cell>
														);
													}
												)}
											</Table.Row>
										);
									})}
								</Table.Body>
							</Table>
							{!!errors.template && (
								<Message
									negative
									content={t(
										'page.answerKeys.message.error.templateFieldIsRequired'
									)}
								/>
							)}
						</Form.Field>
					)}
				</Form>
			</Modal.Content>

			<Modal.Actions>
				<ButtonGDY
					onClick={handleSubmit(onSubmit)}
					loading={createAnswerKey.isLoading || editAnswerKey.isLoading}
					disabled={createAnswerKey.isLoading || editAnswerKey.isLoading}
				>
					{t('component.modal.button.save')}
				</ButtonGDY>
			</Modal.Actions>
		</Modal>
	);
}
