import { AxiosError } from 'axios';
import api from '../../../services/api';

export async function createHomeLinksRequest(data: string) {
	try {
		const response = await api.post('/homeLinks', { content: data });

		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			throw new Error(error.response?.data?.message);
		} else {
			throw new Error();
		}
	}
}
