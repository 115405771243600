import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { Alert } from '../../../components/Alert';
import { queryClient } from '../../../app/App';
import { QuizFormProps } from '../types/quiz.type';
import { answerQuizRequest } from '../requests/answerQuizRequest';
import { AxiosError } from 'axios';

export function useAnswerQuiz() {
	const navigate = useNavigate();

	const answerQuiz = useMutation({
		mutationFn: async (data: QuizFormProps) => {
			const newData = {
				...data,
				competencies: undefined,
				fromAreaId: undefined,
				toAreaId: undefined,
				quizDetailRequestList: data.competencies?.flatMap((obj) =>
					obj.behaviors.map(({ behaviorId, checked }) => ({
						behaviorId,
						checked: !!checked,
					}))
				),
			};

			return answerQuizRequest(newData);
		},
		onSuccess: () => {
			Alert({ message: 'Successfully created!', type: 'success' });
			queryClient.invalidateQueries(['LIST_QUIZES']);
			navigate(-1);
		},
		onError: (error: AxiosError) => {
			Alert({ message: error.message, type: 'error' });
			queryClient.invalidateQueries(['LIST_QUIZES']);
		},
	});

	return { answerQuiz };
}
