import api from '../../../services/api';
import { CompetenceResponseProps } from '../types/competence.type';

export async function findCompetenceByAreaIdRequest(
	areaId: number
): Promise<CompetenceResponseProps[]> {
	const response = await api.get(`/competence/area/${areaId}`);

	return response.data;
}
