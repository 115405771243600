/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
	createContext,
	useState,
	ReactNode,
	useMemo,
	useEffect,
	SetStateAction,
} from 'react';
import { User } from '../types/user.type';
import api from '../../../services/api';
import { findUserRequest } from '../requests/findUserRequest';
import cookies from '../../../utils/cookies';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export type AuthContextData = {
	signed: boolean;
	user: User | undefined;
	token: string | undefined;
	handleGetToken: () => string | null;
	handleFindUser: () => Promise<void>;
	signOut: () => void;
	isSplashLoading: boolean;
	setIsSplashLoading: React.Dispatch<SetStateAction<boolean>>;
};

type AuthProviderProps = {
	children: ReactNode;
};

export const AuthContext = createContext<AuthContextData>(
	{} as AuthContextData
);

export const AuthProvider = ({ children }: AuthProviderProps) => {
	const [user, setUser] = useState<User | undefined>(undefined);
	const [token, setToken] = useState<string | undefined>(undefined);
	const [isSplashLoading, setIsSplashLoading] = useState(false);
	// @ts-ignore
	const { i18n } = useTranslation();
	const navigate = useNavigate();

	const handleLoadData = async () => {
		const accessToken = cookies.getCookie('@TOKEN');

		if (!accessToken) {
			if (window.location.href !== `${window.location.origin}/auth`) {
				window.location.href = `${window.location.origin}/auth`;
				return;
			}
			return;
		}

		setToken(accessToken);
		api.defaults.headers.common.jwt = accessToken;
		handleFindUser();
	};

	const handleGetToken = (): string | null => {
		const params = new URLSearchParams(window.location.search);
		const jwt = params.get('jwt');

		if (!jwt) {
			return null;
		}

		api.defaults.headers.common.jwt = jwt;

		cookies.setCookie('@TOKEN', jwt, 10);
		setToken(jwt);

		return jwt;
	};

	const handleFindUser = async () => {
		try {
			const response = await findUserRequest();

			localStorage.setItem('@USER', JSON.stringify(response));
			const hasCachedLanguage = localStorage.getItem('@LANGUAGE');

			if (response.languageCode && !hasCachedLanguage) {
				i18n.changeLanguage(response.languageCode);
				localStorage.setItem('@LANGUAGE', response.languageCode);
			}
			setUser(response);
		} catch (error) {
			if (error instanceof Error) {
				signOut();
				throw new Error(error.message);
			}
		}
	};

	const signOut = () => {
		delete api.defaults.headers.common.jwt;
		cookies.removeCookie('@TOKEN');
		localStorage.removeItem('@USER');
		setToken(undefined);
		setUser(undefined);
		navigate('/auth');
	};

	useEffect(() => {
		handleLoadData();
	}, []);

	const authContextData: AuthContextData = useMemo(() => {
		return {
			signed: !!token,
			user,
			token,
			handleGetToken,
			handleFindUser,
			signOut,
			isSplashLoading,
			setIsSplashLoading,
		};
	}, [
		user,
		token,
		handleGetToken,
		handleFindUser,
		signOut,
		isSplashLoading,
		setIsSplashLoading,
	]);

	return (
		<AuthContext.Provider value={authContextData}>
			{children}
		</AuthContext.Provider>
	);
};
