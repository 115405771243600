import { useMutation } from '@tanstack/react-query';
import { deleteCompetenceRequest } from '../requests/deleteCompetenceRequest';
import { queryClient } from '../../../app/App';
import { Alert } from '../../../components/Alert';
import { AxiosError } from 'axios';

export function useDeleteCompetence() {
	const deleteCompetence = useMutation({
		mutationFn: async (id: number) => {
			return deleteCompetenceRequest(id);
		},
		onSuccess: () => {
			Alert({ message: 'Successfully deleted!', type: 'success' });
			queryClient.invalidateQueries(['COMPETENCE_LIST']);
		},
		onError: (error: AxiosError) => {
			Alert({ message: error.message, type: 'error' });
		},
	});

	return {
		deleteCompetence,
	};
}
