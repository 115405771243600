import styled from 'styled-components';

export const Container = styled.div`
	padding: ${({ theme }) => `${theme.spacing[1]}px 0`};
	border-bottom: 1px solid #f2f2f2;

	:first-child {
		padding-top: 0;
	}

	:last-child {
		border-bottom: 0;
	}

	.filterGroup__title {
		opacity: 0.8;
		font-size: 1.2em;
		font-weight: bold;
		padding-bottom: ${({ theme }) => `${theme.spacing[1]}px`};
	}

	.filterGroup__content {
		display: flex;
		flex-direction: column;
		gap: ${({ theme }) => `${theme.spacing[2]}px`};

		label {
			font-weight: bold;
			opacity: 0.6;
		}
	}
`;
