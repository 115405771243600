import { useMutation } from '@tanstack/react-query';
import { Alert } from '../../../components/Alert';
import { queryClient } from '../../../app/App';
import { AxiosError } from 'axios';
import { editHomeLinksRequest } from '../requests/editHomeLinksRequest';
import { HomeLinksProps } from '../types/homeLinks';

type Props = {
	setIsHomeLinksEdit: React.Dispatch<React.SetStateAction<boolean>>;
};

export function useEditHomeLinks({ setIsHomeLinksEdit }: Props) {
	const editHomeLinks = useMutation({
		mutationFn: async (data: HomeLinksProps) => {
			return editHomeLinksRequest(data);
		},
		onSuccess: () => {
			Alert({ message: 'Successfully edited!', type: 'success' });
			queryClient.invalidateQueries(['HOME_LINKS']);

			setIsHomeLinksEdit(false);
		},
		onError: (error: AxiosError) => {
			Alert({ message: error.message, type: 'error' });
		},
	});

	return { editHomeLinks };
}
