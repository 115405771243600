import {
	Pagination,
	PaginationProps,
	TableCellProps,
	TableProps,
} from 'semantic-ui-react';
import {
	TableWrapper,
	Table as TableStyled,
	TableHeader as TableHeaderStyled,
	TableBody as TableBodyStyled,
	TablePagination as TablePaginationStyled,
	TableRow as TableRowStyled,
	TableHeaderCell as TableHeaderCellStyled,
	TableCell as TableCellStyled,
} from './styles';

interface IProps {
	children: React.ReactNode;
}

interface ITableProps extends TableProps {
	children: React.ReactNode;
	paddedBottom?: boolean;
	capacityLevelOptionsLength?: number;
}

interface ITableCellProps extends TableCellProps {
	children?: React.ReactNode;
	isIdColumn?: boolean;
	isActionColumn?: boolean;
}

interface ITableHeaderCellProps extends TableCellProps {
	children?: React.ReactNode;
	isIdColumn?: boolean;
	isActionColumn?: boolean;
	isStickyColumn?: boolean;
}

export const Table = ({
	children,
	paddedBottom,
	capacityLevelOptionsLength,
	...rest
}: ITableProps) => (
	<TableWrapper
		paddedBottom={paddedBottom}
		capacityLevelOptionsLength={capacityLevelOptionsLength}
	>
		<TableStyled unstackable {...rest}>
			{children}
		</TableStyled>
	</TableWrapper>
);

const TableHeader = ({ children }: IProps) => (
	<TableHeaderStyled>{children}</TableHeaderStyled>
);

const TableBody = ({ children }: IProps) => (
	<TableBodyStyled>{children}</TableBodyStyled>
);

const TableRow = ({ children }: IProps) => (
	<TableRowStyled>{children}</TableRowStyled>
);

const TableHeaderCell = ({
	children,
	isIdColumn,
	isActionColumn,
	isStickyColumn,
	...rest
}: ITableHeaderCellProps) => (
	<TableHeaderCellStyled
		isIdColumn={isIdColumn}
		isActionColumn={isActionColumn}
		isStickyColumn={isStickyColumn}
		{...rest}
	>
		{children}
	</TableHeaderCellStyled>
);

const TableCell = ({
	children,
	isIdColumn,
	isActionColumn,
	isStickyColumn,
	...rest
}: ITableCellProps) => (
	<TableCellStyled
		isIdColumn={isIdColumn}
		isActionColumn={isActionColumn}
		isStickyColumn={isStickyColumn}
		{...rest}
	>
		{children}
	</TableCellStyled>
);

const TablePagination = ({ ...rest }: PaginationProps) => (
	<TablePaginationStyled>
		<Pagination {...rest} />
	</TablePaginationStyled>
);

Table.Header = TableHeader;
Table.Body = TableBody;
Table.Pagination = TablePagination;
Table.Row = TableRow;
Table.HeaderCell = TableHeaderCell;
Table.Cell = TableCell;
