import { useNavigate, useParams } from 'react-router-dom';
import { Form, Modal } from 'semantic-ui-react';
import { ButtonGDY } from '../../../../components/Form/ButtonGDY';
import { useQuery } from '@tanstack/react-query';
import { findFunctionByIdRequest } from '../../requests/findFunctionByIdRequest';
import { useForm } from 'react-hook-form';
import { useCreateFunction } from '../../hooks/useCreateFunction';
import { useEditFunction } from '../../hooks/useEditFunction';
import { FunctionFormProps } from '../../types/function.type';
import { FormInput } from '../../../../components/Form/FormInput';
import { FormCheckbox } from '../../../../components/Form/FormCheckbox';
import { useTranslation } from 'react-i18next';

export function FunctionFormModal() {
	const { t } = useTranslation();
	const { control, reset, handleSubmit } = useForm<FunctionFormProps>({
		defaultValues: {
			active: true,
		},
	});
	const navigate = useNavigate();

	const { functionId } = useParams<'functionId'>();

	const { createFunction } = useCreateFunction();
	const { editFunction } = useEditFunction();

	useQuery(
		['CAPACITY_LEVEL_BY_ID'],
		async () => {
			const data = await findFunctionByIdRequest(Number(functionId));

			reset({
				id: data.id,
				name: data.name,
				description: data.description,
				order: data.order,
				active: data.active,
			});
		},
		{
			enabled: !!functionId,
			retry: false,
			refetchOnWindowFocus: false,
		}
	);

	const onSubmit = (data: FunctionFormProps) => {
		if (data.id) {
			return editFunction.mutateAsync(data);
		}

		return createFunction.mutateAsync(data);
	};

	return (
		<Modal
			open
			size="tiny"
			onClose={() => navigate(-1)}
			closeIcon
			closeOnDimmerClick={false}
		>
			<Modal.Header>{t('page.functions.functionRegistration')}</Modal.Header>

			<Modal.Content scrolling>
				<Form>
					<FormInput<FunctionFormProps>
						control={control}
						name="name"
						label={t('page.functions.function')}
						required
					/>

					<FormInput<FunctionFormProps>
						control={control}
						name="description"
						label={t('page.functions.description')}
						required
					/>

					<FormInput<FunctionFormProps>
						control={control}
						name="order"
						type="number"
						label={t('page.functions.order')}
						required
					/>

					<FormCheckbox<FunctionFormProps>
						control={control}
						name="active"
						label={t('page.functions.active')}
					/>
				</Form>
			</Modal.Content>

			<Modal.Actions>
				<ButtonGDY
					onClick={handleSubmit(onSubmit)}
					loading={createFunction.isLoading || editFunction.isLoading}
					disabled={createFunction.isLoading || editFunction.isLoading}
				>
					{t('component.modal.button.save')}
				</ButtonGDY>
			</Modal.Actions>
		</Modal>
	);
}
