import { DropdownItemProps } from 'semantic-ui-react';

export function toDropdownItemProps<T>(items: T[]): DropdownItemProps[] {
	return items?.map(
		(item: any): DropdownItemProps => ({
			key: item.id,
			text: item.label ?? item.name ?? item.title,
			value: item.id,
		})
	);
}
