import { Header, Icon, SemanticICONS } from 'semantic-ui-react';
import { Container } from './styles';

type Props = {
	icon?: SemanticICONS;
	title?: string;
	subTitle?: string;
};

export function NoResultsTableInformation({ title, subTitle, icon }: Props) {
	return (
		<Container>
			<Header>
				{icon && <Icon name={icon} />}
				<Header.Content>
					{title}

					{subTitle && <Header.Subheader>{subTitle}</Header.Subheader>}
				</Header.Content>
			</Header>
		</Container>
	);
}
