import { useNavigate, useParams } from 'react-router-dom';
import { Form, Modal } from 'semantic-ui-react';
import { ButtonGDY } from '../../../../components/Form/ButtonGDY';
import { useQuery } from '@tanstack/react-query';
import { findSubareaByIdRequest } from '../../requests/findSubareaByIdRequest';
import { useForm, useWatch } from 'react-hook-form';
import { useCreateSubarea } from '../../hooks/useCreateSubarea';
import { useEditSubarea } from '../../hooks/useEditSubarea';
import { SubareaFormProps } from '../../types/subarea.type';
import { FormInput } from '../../../../components/Form/FormInput';
import { useTranslation } from 'react-i18next';
import { FormDropdown } from '../../../../components/Form/FormDropdown';
import { toDropdownItemProps } from '../../../../utils/toDropdownItemProps';
import { findAreaRequest } from '../../../areas/requests/findAreaRequest';
import { AreaResponseProps } from '../../../areas/types/area.type';

export function SubareaFormModal() {
	const { t } = useTranslation();
	const { control, reset, handleSubmit } = useForm<SubareaFormProps>();
	const navigate = useNavigate();

	const { subareaId } = useParams<'subareaId'>();

	const { createSubarea } = useCreateSubarea();
	const { editSubarea } = useEditSubarea();

	const whatchAreaId = useWatch({ control, name: 'areaId' });

	useQuery(
		['SUBAREA_BY_ID'],
		async () => {
			const data = await findSubareaByIdRequest(Number(subareaId));

			reset({
				id: data.id,
				areaId: data.area.id,
				name: data.name,
			});
		},
		{
			enabled: !!subareaId,
			retry: false,
			refetchOnWindowFocus: false,
		}
	);

	const { data: areaOptions } = useQuery(['AREA_OPTIONS'], async () => {
		const data = await findAreaRequest({});

		return toDropdownItemProps<AreaResponseProps>(data._embedded.areas);
	});

	const onSubmit = (data: SubareaFormProps) => {
		if (data.id) {
			return editSubarea.mutateAsync(data);
		}

		return createSubarea.mutateAsync(data);
	};

	return (
		<Modal
			open
			size="tiny"
			onClose={() => navigate(-1)}
			closeIcon
			closeOnDimmerClick={false}
		>
			<Modal.Header>{t('page.subareas.subareaRegistration')}</Modal.Header>

			<Modal.Content scrolling>
				<Form>
					<FormDropdown<SubareaFormProps>
						control={control}
						name="areaId"
						label={t('page.competencies.area')}
						options={areaOptions ?? []}
						required
					/>
					<FormInput<SubareaFormProps>
						control={control}
						name="name"
						label={t('page.subareas.subarea')}
						required
						disabled={!whatchAreaId}
					/>
				</Form>
			</Modal.Content>

			<Modal.Actions>
				<ButtonGDY
					onClick={handleSubmit(onSubmit)}
					loading={createSubarea.isLoading || editSubarea.isLoading}
					disabled={createSubarea.isLoading || editSubarea.isLoading}
				>
					{t('component.modal.button.save')}
				</ButtonGDY>
			</Modal.Actions>
		</Modal>
	);
}
