import { useAuth } from '../../hooks/useAuth';

import { Alert } from '../../../../components/Alert';
import { Button } from '../../../../components/Button';

import GoodyearLogoImg from '../../../../assets/images/goodyear-logo.png';
import BackgroundImageMock from '../../../../assets/images/login-background.jpg';

import {
	Container,
	Content,
	AuthenticationContent,
	BackgroundContainer,
	BackgroundImage,
	LoginButtons,
} from './styles';
import { useNavigate } from 'react-router-dom';
import { ENV } from '../../../../app/config/env';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { findBackgroundImageRequest } from '../../requests/findBackgroundImageRequest';
import { CSSTransition } from 'react-transition-group';
import { useEffect, useState } from 'react';
import { Dropdown, Icon } from 'semantic-ui-react';

export function Login() {
	const { handleGetToken, handleFindUser } = useAuth();
	const { i18n, t } = useTranslation();
	const navigate = useNavigate();
	const [showBackground, setShowBackground] = useState(false);
	const [countBackground, setCountBackground] = useState(0);
	const INTERVAL = 1000 * 10;

	const {
		data: backgroundData,
		isLoading: isBackgroundLoading,
		isError: isBackgroundError,
	} = useQuery(['BG_IMAGE'], async () => findBackgroundImageRequest(), {
		refetchOnWindowFocus: false,
		refetchInterval: INTERVAL,
	});

	const handleShowBackgroundImage = () => {
		if (!isBackgroundLoading) {
			setShowBackground(true);

			setTimeout(() => {
				setShowBackground(false);
				setCountBackground((prev) => prev + 1);
			}, INTERVAL - 2000);
		}
	};

	useEffect(() => {
		handleShowBackgroundImage();
	}, [backgroundData, isBackgroundLoading]);

	const captureTokenUrl = () => {
		try {
			const token = handleGetToken();

			if (token) {
				handleFindUser();
				navigate('/');
			}
		} catch (error) {
			if (error instanceof Error) {
				Alert({ message: error.message, type: 'error' });
			}
		}
	};

	useEffect(() => {
		captureTokenUrl();
	}, []);

	const handleNavigateLogin = () => {
		window.location.href = `${ENV.REACT_APP_BASE_API_URL}/login`;
	};

	const handleChangeLanguage = (value: string) => {
		i18n.changeLanguage(value);
		localStorage.setItem('@LANGUAGE', i18n.language);
	};

	return (
		<Container>
			<Content>
				<img src={GoodyearLogoImg} alt="Goodyear" draggable={false} />

				<AuthenticationContent>
					<h1>{String(t('application.name')).toUpperCase()}</h1>

					<p>{t('page.login.content.credentialsAccess')}</p>

					<LoginButtons>
						<Button
							title={t('page.login.button.loginGoodyear')}
							iconName="key"
							onClick={handleNavigateLogin}
						/>
					</LoginButtons>

					<Dropdown
						trigger={
							<>
								<Icon name="globe" /> {t(`languages.${i18n.language}` as any)}
							</>
						}
						inline
						options={[
							{
								key: 'pt-BR',
								flag: 'brazil',
								text: 'Português',
								value: 'pt-BR',
								active: ['pt-BR', 'PT'].includes(i18n.language),
							},
							{
								key: 'en-US',
								flag: 'united states',
								text: 'English',
								value: 'en-US',
								active: ['en-US', 'en'].includes(i18n.language),
							},
						]}
						onChange={(_, data) => handleChangeLanguage(data.value as string)}
					/>
				</AuthenticationContent>
			</Content>

			<BackgroundContainer>
				<CSSTransition
					in={showBackground}
					timeout={countBackground > 0 ? 2000 : 0}
					classNames="fade"
					unmountOnExit
				>
					{isBackgroundError ? (
						<BackgroundImage
							src={BackgroundImageMock}
							alt="Background"
							draggable={false}
						/>
					) : (
						<BackgroundImage
							src={`data:image/png;base64,${backgroundData?.byteArray}`}
							alt="Background"
							draggable={false}
						/>
					)}
				</CSSTransition>
			</BackgroundContainer>
		</Container>
	);
}

export default Login;
