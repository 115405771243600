import { Outlet } from 'react-router-dom';
import { Header } from './Header';
import { Sidebar } from './Sidebar';
import Content from './Content';
import { useState } from 'react';

export default function Layout() {
	const [show, setShow] = useState<boolean>(false);

	const handleShow = () => setShow((oldValue) => !oldValue);

	return (
		<>
			<Header handleShow={handleShow} />

			<Sidebar handleShow={handleShow} show={show} setShow={setShow} />

			<Content>
				<Outlet />
			</Content>
		</>
	);
}
