import { useMutation } from '@tanstack/react-query';
import { createCapacityLevelRequest } from '../requests/createCapacityLevelRequest';
import { useNavigate } from 'react-router-dom';
import { CapacityLevelFormProps } from '../types/capacityLevel.type';
import { Alert } from '../../../components/Alert';
import { queryClient } from '../../../app/App';
import { AxiosError } from 'axios';

export function useCreateCapacityLevel() {
	const navigate = useNavigate();

	const createCapacityLevel = useMutation({
		mutationFn: async (data: CapacityLevelFormProps) => {
			return createCapacityLevelRequest(data);
		},
		onSuccess: () => {
			Alert({ message: 'Successfully created!', type: 'success' });
			queryClient.invalidateQueries(['CAPACITY_LEVEL_LIST']);
			navigate(-1);
		},
		onError: (error: AxiosError) => {
			Alert({ message: error.message, type: 'error' });
		},
	});

	return { createCapacityLevel };
}
