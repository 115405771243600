import { Title } from '../../../../components/Typography/Title';
import { Toolbar } from '../../../../components/Toolbar';
import { Table } from '../../../../components/Table';
import { Container } from './styles';
import {
	Button,
	Dropdown,
	Form,
	Icon,
	PaginationProps,
	Popup,
} from 'semantic-ui-react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useDeleteArea } from '../../hooks/useDeleteArea';
import { FilterGroup } from '../../../../components/Form/FilterGroup';
import { Controller, useForm } from 'react-hook-form';
import { useFindAreas } from '../../hooks/useFindAreas';
import { FilterListAreas } from '../../types/area.type';
import { NoResultsTableInformation } from '../../../../components/TableNoDataFound';
import { useTranslation } from 'react-i18next';
import { ModalConfirm } from '../../../../components/ModalConfirm';

export function ListAreas() {
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [item, setItem] = useState<number>();

	const { t } = useTranslation();
	const { control, handleSubmit, reset } = useForm<FilterListAreas>();
	const [page, setPage] = useState(1);
	const [filter, setFilter] = useState<FilterListAreas>();

	const navigate = useNavigate();
	const location = useLocation();

	const { deleteArea } = useDeleteArea();

	const { listAreas } = useFindAreas({ page, filter });

	const handlePageChange = (
		_: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
		data: PaginationProps
	) => {
		setPage(Number(data.activePage));
	};

	const onSubmitFilter = (data: FilterListAreas) => {
		setFilter(data);
		setPage(1);
	};

	const handleResetFilter = () => {
		reset({
			name: '',
		});
		setFilter({
			name: undefined,
		});
	};

	return (
		<Container>
			<Title>{t('title.areas')}</Title>

			<Toolbar
				handleAdd={() => navigate(`${location.pathname}/create`)}
				filter={{
					content: (
						<FilterGroup>
							<Controller
								control={control}
								name="name"
								render={({ field: { value, onChange } }) => (
									<Form.Input
										label={t('page.areas.area')}
										value={value}
										onChange={onChange}
									/>
								)}
							/>
						</FilterGroup>
					),
					onApply: handleSubmit(onSubmitFilter),
					onClear: handleResetFilter,
				}}
			></Toolbar>

			<span>
				<Table>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell content="ID" />

							<Table.HeaderCell content={t('title.areas')} />

							<Table.HeaderCell isActionColumn>
								<Icon name="cog" size="large" />
							</Table.HeaderCell>
						</Table.Row>
					</Table.Header>

					<Table.Body>
						{listAreas?._embedded?.areas?.map(({ id, name }, index) => (
							<Table.Row key={id}>
								<Table.Cell content={index + 1} isIdColumn />
								<Table.Cell content={name} />
								<Table.Cell isActionColumn>
									<Dropdown
										pointing="right"
										trigger={<Icon name="ellipsis vertical" size="large" />}
										icon={null}
									>
										<Dropdown.Menu>
											<span className="actionColumn__buttonGroup">
												<Popup
													content={t('tooltip.edit')}
													trigger={
														<Button
															icon="pencil"
															size="mini"
															color="yellow"
															onClick={() =>
																navigate(`${location.pathname}/update/${id}`)
															}
														/>
													}
												/>

												<Popup
													content={t('tooltip.delete')}
													trigger={
														<Button
															icon="trash"
															size="mini"
															color="red"
															onClick={() => {
																setItem(id);
																setOpenModal(true);
															}}
														/>
													}
												/>
											</span>
										</Dropdown.Menu>
									</Dropdown>
								</Table.Cell>
							</Table.Row>
						))}
					</Table.Body>
				</Table>

				{listAreas?._embedded?.areas?.length ? (
					<Table.Pagination
						page={page}
						activePage={page}
						totalPages={listAreas?.page.totalPages ?? 0}
						boundaryRange={1}
						siblingRange={0}
						onPageChange={handlePageChange}
						size="mini"
						firstItem={null}
						lastItem={null}
						secondary
					/>
				) : (
					<NoResultsTableInformation
						icon="dont"
						title={t('message.noDataFound.title')}
						subTitle={t('message.noDataFound.subTitle')}
					/>
				)}
			</span>
			<Outlet />

			<ModalConfirm
				open={openModal}
				onClose={() => setOpenModal(false)}
				onOpen={() => setOpenModal(true)}
				title={t('component.modal.header.confirmation')}
				description={t('component.modal.content.AreYouSureYouWantToDelete')}
				type="remove"
				size="mini"
				onConfirm={() => {
					item && deleteArea.mutateAsync(item);
					setOpenModal(false);
				}}
				onCancel={() => setOpenModal(false)}
			/>
		</Container>
	);
}
