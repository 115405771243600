import api from '../../../services/api';
import { CompetenceResponseProps } from '../types/competence.type';

export async function findCompetenceByIdRequest(
	id: number
): Promise<CompetenceResponseProps> {
	const response = await api.get(`/competence/${id}`);

	return response.data;
}
