import { Link, useLocation } from 'react-router-dom';
import projectPackage from '../../../../package.json';

import { Icon } from 'semantic-ui-react';

import {
	Container,
	Navigation,
	Items,
	Item,
	Info,
	ToggleButton,
	Logo,
	Category,
	SubItem,
} from './styles';

import { useCallback, useRef, useState } from 'react';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import { menuItems } from './constants/menu';
import { useAuth } from '../../../features/authentication/hooks/useAuth';
import { useTranslation } from 'react-i18next';

interface Props {
	show: boolean;
	setShow: React.Dispatch<React.SetStateAction<boolean>>;
	handleShow: () => void;
}

function Sidebar({ show, setShow, handleShow }: Props) {
	const { t } = useTranslation();
	const { user } = useAuth();
	const ref = useRef(null);
	const location = useLocation();
	const [categoryIndexOpen, setCategoryIndexOpen] = useState<number>();
	const currentYear = new Date().getFullYear();

	useOnClickOutside(ref, () => setShow(false));

	const handleClose = () => {
		if (show) {
			setShow(false);
			setCategoryIndexOpen(0);
		}
	};

	const handleCategoryClick = useCallback(
		(index: number) => {
			const isCategoryOpen = categoryIndexOpen === index;

			if (!show) {
				handleShow();
				return setCategoryIndexOpen(index);
			}

			if (isCategoryOpen) {
				return setCategoryIndexOpen(0);
			}

			return setCategoryIndexOpen(index);
		},
		[show, categoryIndexOpen]
	);

	return (
		<Container show={show} ref={ref}>
			<Navigation>
				<ToggleButton
					show={show}
					name={show ? 'close' : 'bars'}
					onClick={handleShow}
				/>

				<hr style={{ opacity: 0.1 }} />

				<Items show={show}>
					{menuItems.map(
						({ path, name, iconName, restrictedToAdmin, subItems }, index) => {
							if (restrictedToAdmin && !user?.isAdmin) {
								return undefined;
							}

							if (subItems) {
								return (
									<Category
										key={name}
										active={subItems
											.map((subItem) => subItem.path)
											.includes(location.pathname)}
										isSidebarOpen={show}
										isCategoryOpen={categoryIndexOpen === index}
										onClick={() => handleCategoryClick(index)}
									>
										<span className="menu__category--item">
											<Icon name={iconName} />
											<span>
												{t(name as any)}
												<Icon
													name={
														categoryIndexOpen === index
															? 'angle down'
															: 'angle right'
													}
												/>
											</span>
										</span>

										<span className="menu__category--subItems">
											{subItems.map((subItem) => {
												if (subItem.restrictedToAdmin && !user?.isAdmin) {
													return undefined;
												}

												return (
													<SubItem
														key={subItem.name}
														active={location.pathname === subItem.path}
													>
														<Link
															to={subItem.path ?? '*'}
															onClick={handleClose}
														>
															<span>{t(subItem.name as any)}</span>
														</Link>
													</SubItem>
												);
											})}
										</span>
									</Category>
								);
							}

							return (
								<Item key={name} active={location.pathname === path}>
									<Link to={path ?? '*'} onClick={handleClose}>
										<Icon name={iconName} />
										<span>{t(name as any)}</span>
									</Link>
								</Item>
							);
						}
					)}
				</Items>
			</Navigation>

			<Info show={show}>
				<Logo />

				<div className="info__version-and-year">
					<span className="info__version">v{projectPackage.version}</span>
					<span className="info__year">© {currentYear}</span>
				</div>

				<div className="info__sentence">The Goodyear Tire & Rubber Company</div>
			</Info>
		</Container>
	);
}

export { Sidebar };
