import api from '../../../services/api';
import { AreaResponseProps } from '../types/area.type';

export async function findAreaByIdRequest(
	id: number
): Promise<AreaResponseProps> {
	const response = await api.get(`/area/${id}`);

	return response.data;
}
