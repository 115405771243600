import api from '../../../services/api';
import { CapacityLevelResponseProps } from '../types/capacityLevel.type';

export async function findCapacityLevelByIdRequest(
	id: number
): Promise<CapacityLevelResponseProps> {
	const response = await api.get(`/capacityLevel/${id}`);

	return response.data;
}
