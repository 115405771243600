import { useQuery } from '@tanstack/react-query';
import { findCompetenceRequest } from '../requests/findCompetenceRequest';
import { FilterListCompetenciesProps } from '../types/competence.type';

type UseFindCompetencies = {
	page: number;
	filter?: FilterListCompetenciesProps;
};

export function useFindCompetencies({ page, filter }: UseFindCompetencies) {
	const { data: listCompetencies } = useQuery(
		['COMPETENCE_LIST', page, filter],
		async () =>
			findCompetenceRequest({
				...filter,
				pageNumber: page - 1,
				pageSize: 10,
			}),
		{ retry: false }
	);

	return { listCompetencies };
}
