import { SemanticICONS } from 'semantic-ui-react';

type MenuItem = {
	path?: string;
	name: string;
	iconName: SemanticICONS;
	restrictedToAdmin?: boolean;

	subItems?: MenuItem[];
};

export const menuItems: MenuItem[] = [
	{
		path: '/',
		name: 'title.home',
		iconName: 'home',
	},
	{
		path: '/selfAssessments',
		name: 'title.selfAssessments',
		iconName: 'clipboard check',
	},
	{
		name: 'title.administration',
		iconName: 'cog',
		restrictedToAdmin: true,
		subItems: [
			{
				path: '/areas',
				name: 'title.areas',
				iconName: 'clipboard',
			},
			{
				path: '/subareas',
				name: 'title.subareas',
				iconName: 'clipboard',
			},
			{
				path: '/competencies',
				name: 'title.competencies',
				iconName: 'cogs',
			},
			{
				path: '/capacityLevels',
				name: 'title.capacityLevels',
				iconName: 'sort amount up',
			},
			{
				path: '/functions',
				name: 'title.functions',
				iconName: 'address card',
			},
			{
				path: '/behaviors',
				name: 'title.behaviors',
				iconName: 'tasks',
			},
			{
				path: '/answerKeys',
				name: 'title.answerKeys',
				iconName: 'table',
			},
		],
	},
];
