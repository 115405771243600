import api from '../../../services/api';
import { FunctionResponseProps } from '../types/function.type';

export async function findFunctionByIdRequest(
	id: number
): Promise<FunctionResponseProps> {
	const response = await api.get(`/function/${id}`);

	return response.data;
}
