import api from '../../../services/api';
import { QuizInformationResponseProps } from '../types/chart.type';

export async function findQuizInformationRequest(
	id: number
): Promise<QuizInformationResponseProps> {
	const response = await api.get('/quiz/information', {
		params: {
			quizId: id,
		},
	});

	return response.data;
}
