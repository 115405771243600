import { useQuery } from '@tanstack/react-query';
import { findAnswerKeyRequest } from '../requests/findAnswerKeyRequest';
import { FilterListAnswerKeys } from '../pages/ListAnswerKeys';

type UseFindCompetencies = {
	page: number;
	filter?: FilterListAnswerKeys;
};

export function useFindAnswerKeys({ page, filter }: UseFindCompetencies) {
	const { data: listAnswerKeys } = useQuery(
		['TEMPLATE_LIST', page, filter],
		async () =>
			findAnswerKeyRequest({
				...filter,
				pageNumber: page - 1,
				pageSize: 10,
			}),
		{ retry: false }
	);

	return { listAnswerKeys };
}
