import { useQuery } from '@tanstack/react-query';
import { findAreaRequest } from '../requests/findAreaRequest';
import { FilterListAreas } from '../types/area.type';

type UseFindCompetencies = {
	page: number;
	filter?: FilterListAreas;
};

export function useFindAreas({ page, filter }: UseFindCompetencies) {
	const { data: listAreas } = useQuery(
		['AREA_LIST', page, filter],
		async () =>
			findAreaRequest({
				pageNumber: page - 1,
				pageSize: 10,
				name: filter?.name,
			}),
		{ retry: false }
	);

	return { listAreas };
}
