/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Modal } from 'semantic-ui-react';
import { ButtonGDY } from '../../../../components/Form/ButtonGDY';
import { useQuery } from '@tanstack/react-query';
import { findCompetenceByIdRequest } from '../../requests/findCompetenceByIdRequest';
import { useForm } from 'react-hook-form';
import { useCreateCompetence } from '../../hooks/useCreateCompetence';
import { useEditCompetence } from '../../hooks/useEditCompetence';
import { findAreaRequest } from '../../../areas/requests/findAreaRequest';
import { toDropdownItemProps } from '../../../../utils/toDropdownItemProps';
import { AreaResponseProps } from '../../../areas/types/area.type';
import { CompetenceFormProps } from '../../types/competence.type';
import { FormDropdown } from '../../../../components/Form/FormDropdown';
import { FormInput } from '../../../../components/Form/FormInput';
import { FormTextArea } from '../../../../components/Form/FormTextArea';
import { FormCheckbox } from '../../../../components/Form/FormCheckbox';
import { useTranslation } from 'react-i18next';

export function CompetenceFormModal() {
	// @ts-ignore
	const { t } = useTranslation();
	const { control, reset, handleSubmit } = useForm<CompetenceFormProps>({
		defaultValues: {
			active: true,
		},
	});
	const navigate = useNavigate();

	const { competenceId } = useParams<'competenceId'>();

	const { createCompetence } = useCreateCompetence();
	const { editCompetence } = useEditCompetence();

	useQuery(
		['COMPETENCE_BY_ID'],
		async () => {
			const data = await findCompetenceByIdRequest(Number(competenceId));

			reset({
				id: data.id,
				areaId: data.area.id,
				title: data.title,
				description: data.description,
				active: data.active,
			});
		},
		{
			enabled: !!competenceId,
			retry: false,
			refetchOnWindowFocus: false,
		}
	);

	const { data: areaOptions } = useQuery(['AREA_OPTIONS'], async () => {
		const data = await findAreaRequest({});

		return toDropdownItemProps<AreaResponseProps>(data._embedded.areas);
	});

	const onSubmit = (data: CompetenceFormProps) => {
		if (data.id) {
			return editCompetence.mutateAsync(data);
		}

		return createCompetence.mutateAsync(data);
	};

	return (
		<Modal
			open
			size="tiny"
			onClose={() => navigate(-1)}
			closeIcon
			closeOnDimmerClick={false}
		>
			<Modal.Header>
				{t('page.competencies.competenceRegistration')}
			</Modal.Header>

			<Modal.Content scrolling>
				<Form>
					<FormDropdown<CompetenceFormProps>
						control={control}
						name="areaId"
						label={t('page.competencies.area')}
						options={areaOptions ?? []}
						required
					/>

					<FormInput<CompetenceFormProps>
						control={control}
						name="title"
						label={t('page.competencies.competence')}
						required
					/>

					<FormTextArea<CompetenceFormProps>
						control={control}
						name="description"
						label={t('page.competencies.description')}
						required
					/>

					<FormCheckbox<CompetenceFormProps>
						control={control}
						name="active"
						label={t('page.competencies.active')}
					/>
				</Form>
			</Modal.Content>

			<Modal.Actions>
				<ButtonGDY
					onClick={handleSubmit(onSubmit)}
					loading={createCompetence.isLoading || editCompetence.isLoading}
					disabled={createCompetence.isLoading || editCompetence.isLoading}
				>
					{t('component.modal.button.save')}
				</ButtonGDY>
			</Modal.Actions>
		</Modal>
	);
}
