import { AxiosError } from 'axios';
import api from '../../../services/api';

export async function deleteAnswerKeyRequest(subareaId: number) {
	try {
		const response = await api.delete(`/template/${subareaId}`);
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			throw new Error(error.response?.data?.message);
		} else {
			throw new Error();
		}
	}
}
