import { useMutation } from '@tanstack/react-query';
import { deleteAnswerKeyRequest } from '../requests/deleteAnswerKeyRequest';
import { Alert } from '../../../components/Alert';
import { queryClient } from '../../../app/App';
import { AxiosError } from 'axios';

export function useDeleteAnswerKey() {
	const deleteAnswerKey = useMutation({
		mutationFn: async (subareaId: number) => {
			return deleteAnswerKeyRequest(subareaId);
		},
		onSuccess: () => {
			Alert({ message: 'Successfully deleted!', type: 'success' });
			queryClient.invalidateQueries(['TEMPLATE_LIST']);
		},
		onError: (error: AxiosError) => {
			Alert({ message: error.message, type: 'error' });
		},
	});

	return {
		deleteAnswerKey,
	};
}
