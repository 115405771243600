import styled from 'styled-components';

export const Container = styled.div`
	background-color: ${({ theme }) => theme.color.white};
	border-radius: 5px;

	overflow: auto !important;

	.minichartbox__title {
		width: 1200px;
		font-size: 18px;
		line-height: 18px;
		padding: 16px;

		font-weight: bold;
		color: ${({ theme }) => theme.color.text};
	}

	.minichartbox__content {
		height: 100%;
		min-width: 800px;
		padding: 16px;
		color: ${({ color, theme }) => color ?? theme.color.gray};

		display: flex;
		justify-content: center;
	}
`;
