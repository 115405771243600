import { Container, GridRow, TextBox, TextContent } from './styles';
import image from '../../../../assets/images/month.9c65830c.png';
import {
	Divider,
	Form,
	Grid,
	Header,
	Icon,
	Image as ImageSUI,
	Message,
} from 'semantic-ui-react';

import carreiraImg from '../../../../assets/images/carreira.png';
import desenvolvimentoImg from '../../../../assets/images/desenvolvimento.png';
import professionalImg from '../../../../assets/images/profissional.png';

import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { findHomeLinks } from '../../requests/findHomeLinks';
import { useCreateHomeLinks } from '../../hooks/useCreateHomeLinks';
import { useEditHomeLinks } from '../../hooks/useEditHomeLinks';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { HomeLinksProps } from '../../types/homeLinks';
import { ButtonGDY } from '../../../../components/Form/ButtonGDY';
import { useAuth } from '../../../authentication/hooks/useAuth';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useDeleteHomeLinks } from '../../hooks/useDeleteHomeLinks';
import { useTranslation } from 'react-i18next';
import { ModalConfirm } from '../../../../components/ModalConfirm';

function Home() {
	const {
		control,
		formState: { errors },
		handleSubmit,
		reset,
		setValue,
	} = useForm<HomeLinksProps>();
	const { user } = useAuth();
	const [isHomeLinksEdit, setIsHomeLinksEdit] = useState(false);
	const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
	const { t } = useTranslation();

	const handleConfirmDeleteModal = async () => {
		await deleteHomeLinks.mutateAsync(homeLinks?.at(0)?.id as number);

		setIsConfirmModalOpen(false);
	};

	const { data: homeLinks } = useQuery(
		['HOME_LINKS', isHomeLinksEdit],
		async () => {
			const response = await findHomeLinks();

			reset({
				id: response?.at(0)?.id,
				content: response?.at(0)?.content,
			});

			return response;
		},
		{
			retry: false,
			refetchOnWindowFocus: false,
		}
	);

	const watchContent = useWatch({ control, name: 'content' });

	const { createHomeLinks } = useCreateHomeLinks({ setIsHomeLinksEdit });
	const { editHomeLinks } = useEditHomeLinks({ setIsHomeLinksEdit });
	const { deleteHomeLinks } = useDeleteHomeLinks();

	useEffect(() => {
		if (watchContent === '<p><br></p>') {
			setValue('content', '');
		}
	}, [watchContent]);

	function onSubmit(data: HomeLinksProps) {
		if (homeLinks?.at(0)?.id) {
			return editHomeLinks.mutateAsync({
				id: homeLinks.at(0)?.id,
				content: data.content,
			});
		}

		return createHomeLinks.mutateAsync(data.content);
	}

	function handleHomeLinksEdit(
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) {
		event.preventDefault();

		setIsHomeLinksEdit(true);
	}

	return (
		<Container>
			<Grid stackable>
				<GridRow>
					<Grid.Column width="8">
						<ImageSUI src={image} alt="img" />
					</Grid.Column>

					<Grid.Column width="8">
						<GridRow>
							<p>{t('page.home.text01')}</p>
							<p>{t('page.home.text02')}</p>
						</GridRow>

						<Grid.Row as={Grid} verticalAlign="middle">
							<Grid.Column width="3">
								<img src={carreiraImg} alt="Carreira" />
							</Grid.Column>
							<Grid.Column width="13">
								<p>
									<strong>{t('page.home.list.item01.title')}</strong>
									<br />
									{t('page.home.list.item01.content')}
								</p>
							</Grid.Column>
						</Grid.Row>

						<Grid.Row as={Grid} verticalAlign="middle">
							<Grid.Column width="3">
								<img src={desenvolvimentoImg} alt="Desenvolvimento" />
							</Grid.Column>
							<Grid.Column width="13">
								<p>
									<strong>{t('page.home.list.item02.title')}</strong>
									<br />
									{t('page.home.list.item02.content')}
								</p>
							</Grid.Column>
						</Grid.Row>

						<Grid.Row as={Grid} verticalAlign="middle">
							<Grid.Column width="3">
								<img src={professionalImg} alt="Professional" />
							</Grid.Column>
							<Grid.Column width="13">
								<p>
									<strong>{t('page.home.list.item03.title')}</strong>
									<br />
									{t('page.home.list.item03.content')}
								</p>
							</Grid.Column>
						</Grid.Row>

						<Grid.Row style={{ marginTop: 40 }}>
							{isHomeLinksEdit ? (
								<Form onSubmit={handleSubmit(onSubmit)}>
									<Form.Group grouped>
										<Controller
											control={control}
											name="content"
											rules={{ required: true }}
											render={({ field: { value, onChange } }) => (
												<Form.Field>
													<ReactQuill
														theme="snow"
														value={value || ''}
														onChange={onChange}
													/>
												</Form.Field>
											)}
										/>

										{!!errors.content && (
											<Message
												negative
												content={t('message.error.thisFieldIsRequired')}
											/>
										)}
									</Form.Group>

									<Form.Group inline>
										<ButtonGDY type="submit" labelPosition="left" icon>
											<Icon name="edit" />
											{t('component.modal.button.save')}
										</ButtonGDY>
										<Form.Button
											basic
											content={t('component.modal.button.cancel')}
											onClick={() => setIsHomeLinksEdit(false)}
										/>
									</Form.Group>
								</Form>
							) : (
								<>
									<Divider />

									<TextBox basic>
										{homeLinks?.at(0)?.content && (
											<TextContent
												dangerouslySetInnerHTML={{
													__html: homeLinks?.at(0)?.content ?? '',
												}}
											/>
										)}

										{user?.isAdmin && (
											<>
												{!homeLinks?.at(0)?.content && (
													<Header as="h5">
														<Icon name="dont" />
														<Header.Content>
															{t('page.home.noResults.title')}
															<Header.Subheader>
																{t('page.home.noResults.subTitle')}
															</Header.Subheader>
														</Header.Content>
													</Header>
												)}

												<span>
													<Form>
														<Form.Button
															circular
															compact
															basic
															icon="pencil"
															color="yellow"
															onClick={handleHomeLinksEdit}
														/>

														{homeLinks?.at(0)?.id && (
															<Form.Button
																circular
																compact
																basic
																icon="trash"
																color="red"
																onClick={() => setIsConfirmModalOpen(true)}
															/>
														)}
													</Form>
												</span>
											</>
										)}
									</TextBox>
								</>
							)}
						</Grid.Row>
					</Grid.Column>
				</GridRow>
			</Grid>

			<ModalConfirm
				open={isConfirmModalOpen}
				onClose={() => setIsConfirmModalOpen(false)}
				onOpen={() => setIsConfirmModalOpen(true)}
				title={t('component.modal.header.confirmation')}
				description={t('component.modal.content.AreYouSureYouWantToDelete')}
				type="remove"
				size="mini"
				onConfirm={handleConfirmDeleteModal}
				onCancel={() => setIsConfirmModalOpen(false)}
			/>
		</Container>
	);
}

export default Home;
