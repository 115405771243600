import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import wingfootLogo from '../../../assets/images/wingfoot-logo-yellow.png';
import goodyearLogo from '../../../assets/images/goodyear-logo.png';

interface NavigationProps {
	show: boolean;
}

interface NavigationActiveProps {
	active: boolean;
}

interface IToggleButtonProps {
	show?: boolean;
}

type CategoryProps = {
	isSidebarOpen?: boolean;
	isCategoryOpen?: boolean;
	active?: boolean;
};

const HEADER_HEIGHT = '56px';

export const Container = styled.div<NavigationProps>`
	background: rgba(0, 0, 0, 0.82);
	box-shadow: 2px 0 6px 0 rgba(0, 0, 0, 0.4);
	backdrop-filter: blur(12px);
	-webkit-backdrop-filter: blur(12px);

	/* background-color: ${({ theme }) => theme.color.gray}; */
	width: ${({ show }) => (show ? '210px' : '63px')};
	height: calc(100% - ${HEADER_HEIGHT});
	padding: 10px;

	position: fixed;
	top: ${HEADER_HEIGHT};
	left: 0;

	display: flex;
	flex-direction: column;
	justify-content: space-between;

	transition: ${({ theme }) => theme.transition.fast};
	overflow: hidden;

	z-index: 30;

	@media (max-width: 460px) {
		width: 80%;
		padding: 20px 10px;

		left: ${({ show }) => (show ? '0' : '-80%')};

		overflow: hidden;

		hr {
			display: none;
		}
	}
`;

export const Navigation = styled.nav`
	width: 100%;

	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => `${theme.spacing[1]}px`};
`;

export const Items = styled.ul<NavigationProps>`
	width: ${({ show }) => (show ? '100%' : 'none')};
	margin: 0;
	padding: 0;

	display: flex;
	flex-direction: column;
	gap: 10px;

	list-style: none;

	a,
	.menu__category--item,
	.menu__item {
		span {
			display: ${({ show }) => !show && 'none'};
			white-space: nowrap;
		}
	}
`;

export const ToggleButton = styled(Icon)<IToggleButtonProps>`
	height: 30px !important;
	width: 100% !important;
	padding: 0 10px;
	color: ${({ theme }) => theme.color.white};

	display: flex !important;
	align-items: center;
	justify-content: ${({ show }) =>
		show ? 'flex-end !important' : 'center !important'};

	cursor: pointer;

	@media (max-width: 460px) {
		display: none !important;
	}
`;

export const Category = styled.li<CategoryProps>`
	min-height: 40px;
	height: ${({ isSidebarOpen, isCategoryOpen }) =>
		isSidebarOpen && isCategoryOpen ? '100%' : '40px'};

	width: 100%;
	border-radius: 5px;

	display: block;

	transition: ${({ theme }) => theme.transition.fast};
	overflow: hidden;

	color: ${({ theme, active }) =>
		active ? theme.color.secondary : theme.color.white} !important;

	cursor: pointer;

	i {
		font-size: 16px;
	}

	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;

	.menu__category--item {
		height: 40px;
		width: 100%;
		padding: 12px;
		border-radius: 5px;

		display: block;
		white-space: nowrap;

		> i:first-child {
			margin-right: 20px;
		}

		i:last-child {
			float: right;
		}

		&:hover {
			background-color: #ffffff30;
		}
	}

	.menu__category--subItems {
		display: block;

		i {
			margin-right: 20px;
		}
	}

	@media (max-width: 460px) {
		i {
			font-size: 16px !important;
		}
	}
`;

export const SubItem = styled.li<NavigationActiveProps>`
	margin-left: 38px;

	a {
		height: 40px;
		margin: ${({ theme }) => `${theme.spacing[1]}px 0`};
		padding: 12px;
		border-radius: 5px;

		display: block;
		white-space: nowrap;

		transition: ${({ theme }) => theme.transition.normal};

		&:hover {
			background-color: #ffffff30;
		}

		color: ${({ theme, active }) =>
			active ? theme.color.secondary : theme.color.white} !important;
	}

	@media (max-width: 460px) {
		a,
		i {
			font-size: 16px !important;
		}
	}
`;

export const Item = styled.li<NavigationActiveProps>`
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;

	a {
		height: 40px;
		width: 100%;
		padding: 12px;
		border-radius: 5px;

		display: block;
		white-space: nowrap;

		transition: ${({ theme }) => theme.transition.normal};

		&:hover {
			background-color: #ffffff30;
		}

		color: ${({ theme, active }) =>
			active ? theme.color.secondary : theme.color.white} !important;

		i {
			font-size: 16px;
			margin-right: 20px;
		}
	}

	@media (max-width: 460px) {
		a,
		i {
			font-size: 16px !important;
		}
	}
`;

export const Info = styled.div<NavigationProps>`
	color: ${({ theme }) => theme.color.white};

	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 8px;

	.info__version-and-year {
		display: flex;
		flex-direction: row;
		justify-content: center;
		flex-wrap: wrap;
		gap: 5px;

		.info__version {
			display: ${({ show }) => (show ? 'flex' : 'none')};
		}

		.info__year {
			white-space: nowrap;
		}
	}

	.info__sentence {
		text-align: center;

		display: ${({ show }) => (show ? 'block' : 'none')};
	}

	@media (max-width: 460px) {
		width: 100%;
		justify-content: center;
	}
`;

export const Logo = styled.img`
	height: 30px;
	content: url(${wingfootLogo});
	display: block;

	@media (max-width: 460px) {
		height: 30px;
		content: url(${goodyearLogo});
	}
`;
