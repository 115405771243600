import api from '../../../services/api';
import { PageableResponse } from '../../../types/PageableResponse.types';
import {
	FilterListCapacityLevels,
	ListCapacityLevelResponse,
} from '../types/capacityLevel.type';

type Props = FilterListCapacityLevels & {
	pageNumber?: number;
	pageSize?: number;
};

export async function findCapacityLevelRequest({
	name,
	active,

	pageNumber,
	pageSize,
}: Props): Promise<PageableResponse<ListCapacityLevelResponse>> {
	const response = await api.get('/capacityLevel', {
		params: {
			active: active ?? undefined,
			name: name || undefined,
			page: pageNumber,
			size: pageSize,
		},
	});

	return response.data;
}
