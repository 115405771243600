import { AxiosError } from 'axios';
import api from '../../../services/api';
import { UserResponse } from '../types/user.type';

export async function findUserRequest(): Promise<UserResponse> {
	try {
		const { data } = await api.get<UserResponse>('/user');
		return data;
	} catch (error) {
		if (error instanceof AxiosError) {
			throw new Error(error.response?.data.message);
		}

		throw new Error();
	}
}
