import { AxiosError } from 'axios';
import api from '../../../services/api';
import { QuizFormRequestProps } from '../types/quiz.type';

export async function answerQuizRequest(data: QuizFormRequestProps) {
	try {
		const response = await api.post('/quiz', data);

		return response.data;
	} catch (error) {
		if (error instanceof AxiosError && error.response?.data?.message) {
			throw new Error(error.response?.data?.message);
		} else {
			throw new Error('Create Error');
		}
	}
}
