import { useQuery } from '@tanstack/react-query';
import { findCompetenceRequest } from '../../competencies/requests/findCompetenceRequest';
import { findFunctionRequest } from '../../functions/requests/findFunctionRequest';
import { useMemo, useState } from 'react';
import { toDropdownItemProps } from '../../../utils/toDropdownItemProps';
import { findCapacityLevelRequest } from '../../capacityLevels/requests/findCapacityLevelRequest';
import { findAreaRequest } from '../../areas/requests/findAreaRequest';
import { findSubareaByAreaIdRequest } from '../../subareas/requests/findSubareaByAreaIdRequest';
import { GroupBase } from 'react-select';
import { LoadOptions } from 'react-select-async-paginate';
import { CompetenceResponseProps } from '../../competencies/types/competence.type';
import { OptionType } from '../types/template.type';

type Props = {
	areaId: number;
	subareaId: number;
};

export function useAnswerKeyFormData({ areaId }: Props) {
	const [competencies, setCompetencies] = useState<CompetenceResponseProps[]>(
		[]
	);

	const { data: areas } = useQuery(
		['AREA_LIST'],
		async () => (await findAreaRequest({}))._embedded.areas,
		{
			retry: false,
		}
	);

	const { data: subareas } = useQuery(
		['SUBAREA_OPTIONS', areaId],
		async () => findSubareaByAreaIdRequest(areaId),
		{
			enabled: !!areaId,
			retry: false,
			refetchOnWindowFocus: false,
		}
	);

	const { data: functions } = useQuery(
		['FUNCTION_LIST', areaId],
		async () => (await findFunctionRequest({}))._embedded.functions,
		{
			retry: false,
			enabled: !!areaId,
		}
	);

	const { data: capacityLevels } = useQuery(
		['CAPACITY_LEVEL_OPTIONS', areaId],
		async () => (await findCapacityLevelRequest({}))._embedded.capacityLevels,
		{
			retry: false,
			enabled: !!areaId,
		}
	);

	const areaOptions = useMemo(
		() => areas && toDropdownItemProps(areas),
		[areas]
	);

	const subareaOptions = useMemo(
		() => subareas && toDropdownItemProps(subareas),
		[subareas]
	);

	const functionOptions = useMemo(
		() => functions && toDropdownItemProps(functions),
		[functions]
	);

	const capacityLevelOptions = useMemo(
		() => capacityLevels && toDropdownItemProps(capacityLevels),
		[capacityLevels]
	);

	const loadCompetenceOptions: LoadOptions<
		OptionType,
		GroupBase<OptionType>,
		{ page: number }
	> = async (search, loadedOptions, { page }: any) => {
		const response = await findCompetenceRequest({
			areaId,
			title: search || undefined,
			pageNumber: page - 1,
			pageSize: 20,
		});

		const {
			_embedded: { competencies },
			page: { totalPages },
		} = response;

		const options: OptionType[] = competencies?.map((option) => ({
			value: option.id,
			label: option.title,
			...option,
		}));

		const newOptions = [...loadedOptions, ...options];
		setCompetencies((prev) => [...prev, ...competencies]);

		const hasMore = page < totalPages;

		return {
			options: newOptions,
			hasMore,
			additional: {
				page: page + 1,
			},
		};
	};

	return {
		areas,
		subareaOptions,
		competencies,
		functions,
		capacityLevels,

		loadCompetenceOptions,

		functionOptions,
		capacityLevelOptions,
		areaOptions,
	};
}
