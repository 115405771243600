import { useEffect, useState } from 'react';

export default function useMediaQuery(maxValue: number) {
	const [width, setWidth] = useState<number>(window.innerWidth);

	function handleWindowSizeChange() {
		setWidth(window.innerWidth);
	}

	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		};
	}, []);

	const isWindowSizeChange = width < maxValue;

	return {
		isWindowSizeChange,
	};
}
