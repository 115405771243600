import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    body, input, a, button, h1, h2, h3, h4, h5, h6 {
        font-family: 'Barlow', Arial, Helvetica, sans-serif;
        font-size: 14px;
    }

    body {
        background-color: ${({ theme }) => theme.color.background};
    }

`;
