import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import ReactGA from 'react-ga4';
import { ToastContainer } from 'react-toastify';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { AuthProvider } from '../features/authentication/contexts/Auth';
import Routes from './routes';

import { GlobalStyle } from './styles/global';
import theme from './styles/theme';

import i18n from './locales';
import { ENV } from './config/env';
import { I18nextProvider } from 'react-i18next';

export const queryClient = new QueryClient();

ReactGA.initialize(ENV.REACT_APP_GA);

function App() {
	return (
		<BrowserRouter>
			<QueryClientProvider client={queryClient}>
				<AuthProvider>
					<ThemeProvider theme={theme}>
						<I18nextProvider i18n={i18n}>
							<Routes />
							<GlobalStyle />
						</I18nextProvider>
						<ToastContainer />
					</ThemeProvider>
				</AuthProvider>
			</QueryClientProvider>
		</BrowserRouter>
	);
}

export default App;
