import api from '../../../services/api';
import { PageableResponse } from '../../../types/PageableResponse.types';
import {
	FilterListBehaviors,
	ListBehaviorResponse,
} from '../types/behavior.type';

type Props = FilterListBehaviors & {
	pageNumber: number;
	pageSize: number;
};

export async function findBehaviorRequest(
	params: Props
): Promise<PageableResponse<ListBehaviorResponse>> {
	const response = await api.get('/behavior', {
		params: {
			...params,
			page: params.pageNumber,
			size: params.pageSize,
		},
	});

	return response.data;
}
