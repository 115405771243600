import {
	Control,
	Controller,
	FieldValues,
	Path,
	useFormState,
} from 'react-hook-form';
import { Form, FormCheckboxProps } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

type Props<T extends FieldValues> = FormCheckboxProps & {
	control: Control<T>;
	name: Path<T>;
	required?: boolean;
	errorMessage?: string;

	label: string;
};

export function FormCheckbox<T extends FieldValues>({
	control,
	name,
	required,
	errorMessage,

	label,
	...rest
}: Props<T>) {
	const { t } = useTranslation();

	const { errors } = useFormState<T>({
		control,
	});

	return (
		<Controller
			control={control}
			name={name}
			rules={{ required }}
			render={({ field: { value, onChange } }) => (
				<Form.Checkbox
					toggle
					label={label}
					checked={value}
					onChange={(_, data) => onChange(data.checked)}
					required={required}
					error={
						required &&
						errors[name] &&
						(errorMessage ?? t('message.error.thisFieldIsRequired'))
					}
					{...rest}
				/>
			)}
		/>
	);
}
