/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Title } from '../../../../components/Typography/Title';
import { Toolbar } from '../../../../components/Toolbar';
import { Table } from '../../../../components/Table';
import { Container } from './styles';
import {
	Button,
	Dropdown,
	Form,
	Icon,
	Label,
	PaginationProps,
	Popup,
} from 'semantic-ui-react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { FilterGroup } from '../../../../components/Form/FilterGroup';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useDeleteBehavior } from '../../hooks/useDeleteBehavior';
import { useFindBehaviors } from '../../hooks/useFindBehaviors';
import { useQuery } from '@tanstack/react-query';
import { toDropdownItemProps } from '../../../../utils/toDropdownItemProps';
import { findCapacityLevelRequest } from '../../../capacityLevels/requests/findCapacityLevelRequest';
import { FilterListBehaviors } from '../../types/behavior.type';
import { findAreaRequest } from '../../../areas/requests/findAreaRequest';
import { findCompetenceByAreaIdRequest } from '../../../competencies/requests/findCompetenceByAreaIdRequest';
import { NoResultsTableInformation } from '../../../../components/TableNoDataFound';
import { useTranslation } from 'react-i18next';
import { TableHealderProps } from '../../../../types/table.types';
import { ModalConfirm } from '../../../../components/ModalConfirm';

const tableHeader: TableHealderProps[] = [
	{ name: 'page.behaviors.area' },
	{ name: 'page.behaviors.competence' },
	{ name: 'page.behaviors.capacityLevel' },
	{ name: 'page.behaviors.behavior' },
	{ name: 'page.behaviors.active', width: 2, textAlign: 'center' },
];

export function ListBehaviors() {
	// @ts-ignore
	const { t } = useTranslation();
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [item, setItem] = useState<number>();

	const { control, handleSubmit, reset, setValue } =
		useForm<FilterListBehaviors>({
			defaultValues: {
				active: true,
			},
		});
	const [page, setPage] = useState(1);
	const [filter, setFilter] = useState<FilterListBehaviors>({ active: true });

	const navigate = useNavigate();
	const location = useLocation();

	const { listBehaviors } = useFindBehaviors({ page, filter });
	const { deleteBehavior } = useDeleteBehavior();

	const watchAreaId = useWatch({ control, name: 'areaId' });

	const { data: areaOptions } = useQuery(['AREA_OPTIONS'], async () => {
		const response = await findAreaRequest({});

		return toDropdownItemProps(response._embedded.areas);
	});

	const { data: competenceOptions } = useQuery(
		['COMPETENCE_BY_AREA_ID_OPTIONS', watchAreaId],
		async () => {
			const response = await findCompetenceByAreaIdRequest(Number(watchAreaId));

			setValue('competenceId', '');

			return toDropdownItemProps(response);
		},
		{
			retry: false,
			refetchOnWindowFocus: false,
			enabled: !!watchAreaId,
		}
	);

	const { data: capacityLevelOptions } = useQuery(
		['CAPACITY_LEVEL_OPTIONS'],
		async () => {
			const response = findCapacityLevelRequest({});

			return toDropdownItemProps((await response)._embedded.capacityLevels);
		}
	);

	const onSubmitFilter = (data: FilterListBehaviors) => {
		setFilter(data);
		setPage(1);
	};

	const handleResetFilter = () => {
		reset({
			areaId: '',
			description: '',
			competenceId: '',
			capacityLevelId: '',
			active: true,
		});
		setFilter({
			areaId: undefined,
			description: undefined,
			competenceId: undefined,
			capacityLevelId: undefined,
			active: true,
		});
	};

	const handlePageChange = (
		_: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
		data: PaginationProps
	) => {
		setPage(Number(data.activePage));
	};

	return (
		<Container>
			<Title>{t('title.behaviors')}</Title>

			<Toolbar
				handleAdd={() => navigate(`${location.pathname}/create`)}
				filter={{
					content: (
						<>
							<FilterGroup>
								<Controller
									control={control}
									name="areaId"
									render={({ field: { value, onChange } }) => (
										<Form.Dropdown
											label={t('page.behaviors.area')}
											selection
											options={areaOptions ?? []}
											value={value ?? undefined}
											onChange={(_, data) => onChange(data.value)}
										/>
									)}
								/>

								<Controller
									control={control}
									name="competenceId"
									render={({ field: { value, onChange } }) => (
										<Form.Dropdown
											label={t('page.behaviors.competence')}
											selection
											options={competenceOptions ?? []}
											value={value ?? undefined}
											onChange={(_, data) => onChange(data.value)}
											disabled={!watchAreaId}
										/>
									)}
								/>

								<Controller
									control={control}
									name="capacityLevelId"
									render={({ field: { value, onChange } }) => (
										<Form.Dropdown
											label={t('page.behaviors.capacityLevel')}
											selection
											options={capacityLevelOptions ?? []}
											value={value ?? undefined}
											onChange={(_, data) => onChange(data.value)}
										/>
									)}
								/>

								<Controller
									control={control}
									name="description"
									render={({ field: { value, onChange } }) => (
										<Form.TextArea
											label={t('page.behaviors.behavior')}
											value={value}
											onChange={onChange}
										/>
									)}
								/>
							</FilterGroup>

							<FilterGroup>
								<Controller
									control={control}
									name="active"
									render={({ field: { value, onChange } }) => (
										<Form.Radio
											toggle
											label={t('page.behaviors.active')}
											checked={value}
											onChange={(_, data) => onChange(data.checked)}
										/>
									)}
								/>
							</FilterGroup>
						</>
					),
					onApply: handleSubmit(onSubmitFilter),
					onClear: handleResetFilter,
				}}
			></Toolbar>

			<span>
				<Table>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell content="ID" isIdColumn />
							{tableHeader.map(({ name, width, textAlign }) => (
								<Table.HeaderCell
									key={name}
									width={width}
									textAlign={textAlign}
								>
									{t(name as any)}
								</Table.HeaderCell>
							))}

							<Table.HeaderCell isActionColumn>
								<Icon name="cog" size="large" />
							</Table.HeaderCell>
						</Table.Row>
					</Table.Header>

					<Table.Body>
						{listBehaviors?._embedded?.behaviors?.map(
							(
								{ id, competence, description, capacityLevel, active },
								index
							) => (
								<Table.Row key={id}>
									<Table.Cell content={index + 1} />
									<Table.Cell content={competence?.area?.name} />
									<Table.Cell content={competence?.title} />
									<Table.Cell content={capacityLevel?.name} />
									<Table.Cell content={description} />
									<Table.Cell width={2} textAlign="center">
										<Label
											color={active ? 'green' : 'red'}
											content={active ? 'Active' : 'Deactivate'}
										/>
									</Table.Cell>
									<Table.Cell isActionColumn>
										<Dropdown
											pointing="right"
											trigger={<Icon name="ellipsis vertical" size="large" />}
											icon={null}
										>
											<Dropdown.Menu>
												<span className="actionColumn__buttonGroup">
													<Popup
														content={t('tooltip.edit')}
														trigger={
															<Button
																icon="pencil"
																size="mini"
																color="yellow"
																onClick={() =>
																	navigate(`${location.pathname}/update/${id}`)
																}
															/>
														}
													/>

													<Popup
														content={t('tooltip.delete')}
														trigger={
															<Button
																icon="trash"
																size="mini"
																color="red"
																onClick={() => {
																	setItem(id);
																	setOpenModal(true);
																}}
															/>
														}
													/>
												</span>
											</Dropdown.Menu>
										</Dropdown>
									</Table.Cell>
								</Table.Row>
							)
						)}
					</Table.Body>
				</Table>

				{listBehaviors?._embedded?.behaviors?.length ? (
					<Table.Pagination
						page={page}
						activePage={page}
						totalPages={listBehaviors?.page.totalPages ?? 0}
						boundaryRange={1}
						siblingRange={0}
						onPageChange={handlePageChange}
						size="mini"
						firstItem={null}
						lastItem={null}
						secondary
					/>
				) : (
					<NoResultsTableInformation
						icon="dont"
						title={t('message.noDataFound.title')}
						subTitle={t('message.noDataFound.subTitle')}
					/>
				)}
			</span>

			<Outlet />

			<ModalConfirm
				open={openModal}
				onClose={() => setOpenModal(false)}
				onOpen={() => setOpenModal(true)}
				title={t('component.modal.header.confirmation')}
				description={t('component.modal.content.AreYouSureYouWantToDelete')}
				type="remove"
				size="mini"
				onConfirm={() => {
					item && deleteBehavior.mutateAsync(item);
					setOpenModal(false);
				}}
				onCancel={() => setOpenModal(false)}
			/>
		</Container>
	);
}
