import { useNavigate, useParams } from 'react-router-dom';
import { Form, Modal } from 'semantic-ui-react';
import { ButtonGDY } from '../../../../components/Form/ButtonGDY';
import { useQuery } from '@tanstack/react-query';
import { findAreaByIdRequest } from '../../requests/findAreaByIdRequest';
import { useForm } from 'react-hook-form';
import { useCreateArea } from '../../hooks/useCreateArea';
import { useEditArea } from '../../hooks/useEditArea';
import { AreaFormProps } from '../../types/area.type';
import { FormInput } from '../../../../components/Form/FormInput';
import { useTranslation } from 'react-i18next';

export function AreaFormModal() {
	const { t } = useTranslation();
	const { control, reset, handleSubmit } = useForm<AreaFormProps>();
	const navigate = useNavigate();

	const { areaId } = useParams<'areaId'>();

	const { createArea } = useCreateArea();
	const { editArea } = useEditArea();

	useQuery(
		['AREA_BY_ID'],
		async () => {
			const data = await findAreaByIdRequest(Number(areaId));

			reset({
				id: data.id,
				name: data.name,
			});
		},
		{
			enabled: !!areaId,
			retry: false,
			refetchOnWindowFocus: false,
		}
	);

	const onSubmit = (data: AreaFormProps) => {
		if (data.id) {
			return editArea.mutateAsync(data);
		}

		return createArea.mutateAsync(data);
	};

	return (
		<Modal
			open
			size="tiny"
			onClose={() => navigate(-1)}
			closeIcon
			closeOnDimmerClick={false}
		>
			<Modal.Header>{t('page.areas.areaRegistration')}</Modal.Header>

			<Modal.Content scrolling>
				<Form>
					<FormInput<AreaFormProps>
						control={control}
						name="name"
						label={t('page.areas.area')}
						required
					/>
				</Form>
			</Modal.Content>

			<Modal.Actions>
				<ButtonGDY
					onClick={handleSubmit(onSubmit)}
					loading={createArea.isLoading || editArea.isLoading}
					disabled={createArea.isLoading || editArea.isLoading}
				>
					{t('component.modal.button.save')}
				</ButtonGDY>
			</Modal.Actions>
		</Modal>
	);
}
