import { AxiosError } from 'axios';
import api from '../../../services/api';
import { SubareaFormProps } from '../types/subarea.type';

export async function editSubareaRequest({
	id,
	areaId,
	name,
}: SubareaFormProps) {
	try {
		const response = await api.put('/sub-area', {
			id,
			areaId,
			name,
		});

		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			throw new Error(error.response?.data?.message);
		} else {
			throw new Error();
		}
	}
}
